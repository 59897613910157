.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: var(--navColor107);
    background-image: url('PageNotFoundBC.jpg'); /* Add your background image */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    text-align: center;
    color: var(--navColor1012);/* Dark gray for better contrast */
    
}
.not-found-image {
    width: 150px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space below the image */
}

.not-found-title {
    font-size: 4rem;
    margin: 0;
    opacity: 0; /* Start invisible */
    transform: scale(0.5); /* Start small */
    animation: fadeInScale 1s ease forwards;
    font-family: var(--firstChoiceFont) /* Add your desired font family here *//* Add your desired font family here */
}

.not-found-message {
    font-size: 1.5rem;
    margin-top: 10px;
    opacity: 0; /* Start invisible */
    transform: translateY(20px); /* Start below */
    animation: slideIn 1s ease forwards 0.5s;
    font-family: var(--firstChoiceFont) /* Add your desired font family here */
}
.go-home-button {
    margin-top: 20px;
    padding: 12px 24px; /* Increased padding for a larger button */
    font-size: 1.2rem; /* Slightly larger font size */
    color: var(--navColor109);
    background: var(--navColor1010);
    border: none;
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Smooth transitions */
    box-shadow: 0 4px 8px   var(--navShadowColor1); /* Subtle shadow for depth */
}

.go-home-button:hover {
    background: var(--navColor1011);
    transform: translateY(-2px); /* Slightly lift the button */
    box-shadow: 0 6px 12px  var( --navShadowColor6); /* Increased shadow on hover */
}

.go-home-button:active {
    transform: translateY(0); /* Reset position when active */
    box-shadow: 0 4px 8px var(--navShadowColor7); /* Return shadow */
}


.go-home-button:hover {
    background: var(--navColor57); /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}
.HomePageIcon {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    width: 80px; /* Maintain the icon's width */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effects */
    box-shadow: 0 4px 8px var(--navShadowColor1); /* Subtle shadow for depth */
    border-radius: 80px; /* Set border radius for rounded corners */
}

.HomePageIcon:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
    box-shadow: 0 6px 12px var(--navShadowColor2); /* Increased shadow on hover */
}


@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
