/* Footer.css */

.footer {
  background-color: var(--navColor1);
  color: var(--navColor73);
  padding: 10px;
  /* margin: 10px; */
  text-align: center;
  /* font-family: Arial, sans-serif; */
}

.footer-top {
  /* background-color: #89e8b2; */
  background-color: var(--navColor1);
  /* padding: 10px; */
  color: var(--navColor7);
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons a {
  /* color: #fff; */
  color: var(--navColor7);
  margin: 0 10px;
  font-size: 1.2em;
  transition: transform 0.3s ease;
  /* Add transition for smooth scaling */
}

.Footer_Febsbook_icon,
.Footer_Linkdin_icon {
  color: var(--navColor74);
}

.Footer_youtub_icon {
  color: var(--navColor15);
}

.Footer_Insta_icon {
  /* color: var(--navColor6); */
  color: var(--navColor75);
  /* background-color: red; */
}

.Footer_whatsapp_icon {
  color: var(--navColor76);
  font-weight: bolder;
  background: var(--navColor73);
}

.icons :hover {
  transform: scale(1.6);
  /* Scale the icon up slightly on hover */
  /* color: #4ce890; */
  /* color: rgb(80, 233, 63); */
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* padding: 20px 0; */
  text-align: left;
  /* Ensure text is aligned to the left */
}

.footer-section {
  text-align: center;
  flex: 1;
  margin: 10px;
  min-width: 200px;
  text-transform: uppercase;
  /* border: 2px solid var(--navColor7); */
}

.footer-section.contact {
  display: none;
}

.Componayproducts {
  text-align: center;
}

.company p {
  font-family: var(--firstChoiceFont);
}

.gogole_link {
  margin-right: 5rem;
  /* border: 2px solid var(--navColor7); */
  font-family: var(--firstChoiceFont);
}

.google_revive {
  height: 230px;
}

.gogole_link iframe {
  /* display:  flex;
    flex-direction: column; */
  /* width: 100%;
    height: 500px; */
  border: none;
  /* Optional: Remove iframe border */
  overflow: auto;
  /* Hide scrollbars but allow scrolling */
  scrollbar-width: none;
  /* Firefox */
  background: var(--navColor6);
}

iframe::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.footer-section h5 {
  font-weight: bold;
  margin-bottom: 10px;
  font-family: var(--firstChoiceFont);
}

.footer-section ul {
  /* list-style: none; */
  padding: 0;
  margin: 0;
  /* Remove default margin */
  /* Align list items to the left */
}

.footer-section ul li {
  margin-bottom: 5px;
  text-align: left !important;
  text-transform: capitalize;
  /* Ensure list items are aligned to the left */
  margin-left: 30%;
  font-family: var(--firstChoiceFont);
  display: flex;
  /* Use Flexbox */
  flex-direction: column;
  /* Stack items vertically on small screens */
  /* align-items: center; */
  /* Center items */
  text-align: center;
  /* Center text */
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--navColor48);
  padding: 10px 0;
  color: var(--navColor7);
  margin-top: 25px;
  font-family: var(--firstChoiceFont);
}

.ShriKrishna-name {
  color: var(--navColor33);
  font-weight: bold;
  margin-right: auto; /* Ensures alignment on the left */
}

.nebula-color {
  margin-left: auto; /* Ensures alignment on the right */
  margin-right: 1.5em;
}

.kgf {
  color: var(--navColor7);
}

.e-color {
  font-size: 1.2em;
  color: var(--navColor38);
}


.Footer_Tritment_links {
  text-decoration: none;
  color: var(--navColor73);
}

/* Responsive Styles */
@media (max-width: 778px) {
  .footer-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left !important;
  }

  .social-icons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* Add space between social icons */
  }

  .footer-section ul li {
    /* margin-bottom: 5px; */
    justify-content: center;
    margin-left: 0px;
  }
}

@media (min-width: 600px) {
  .footer-bottom {
    /* display: flex; */
    /* justify-content: center;  */
    flex-direction: row;
    /* Change to row layout on larger screens */
    justify-content: space-between;
    /* Distribute space evenly */
    padding: 10px 20px;
    /* Adjust padding for larger screens */
  }
}

.ShriKrishna-name {
  color: var(--navColor33);
  margin-right: 1em;
  /* Use margin-right for spacing */
  font-weight: bold;
}

.nebula-color {
  color: var(--navColor15);
  font-weight: bolder;
}

.FooterImg {
  width: 120px;
  /* Ensure width and height are equal for a circle */
  height: 120px;
  /* Ensure width and height are equal for a circle */
  border-radius: 50%;
  /* margin-right: 20px; */
  object-fit: cover;
  /* Cover ensures the image fills the circle */
  /* animation: scaleRotate 5s ease-in-out infinite; */
  /* Apply animation */
  filter: contrast(154%);
  padding: -10px;
  box-shadow: 2px 2px 4px var(--navShadowColor7);
  background-color: var(--navColor7)
}

@media (max-width: 450px) {
  .footer-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .footer {
    justify-content: center;
  }

  .footer-section {
    /* border: 2px solid var(--navColor7); */
    text-align: center;
  }

  .footer-section ul li {
    /* margin-bottom: 5px; */
    text-align: left;
    margin-left: 20%;
  }

  .contact p {
    text-align: left;
    margin-left: 40px;
  }

  /* .Componayproducts li{
     margin-left: 50px;/
     
    } */
  .gogole_link {
    height: 80%;
    margin-right: 0rem;
  }
}

/* .footer_icon a{
   
    margin-left: 15px;
    } */

.nebula-color {
  color: var(--navColor15);
  font-weight: bolder;
}