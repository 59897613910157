.Sidebar_footer_icon {
    position: fixed;
    bottom: 10px;
    right: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 999; /* Ensures it's always on top */
    /* background-color: var(--navColor79); */
      
    
  }
  
  .Sidebar_footer_icon_link {
    /* background-color: #333; */
    /* background-color: #b0ef91; */
    /* color: rgb(24, 24, 200); */
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    transition: background-color 0.3s ease;
    
  }
  .Sidebar_footer_icon_Booknow {
    color: var(--navColor7);
  }
  .Sidebar_footer_icon_whatsapp{
    color: var(--navColor80);
    font-weight: bolder;
    text-shadow: 2px 2px 2px var(--navColor7);
   
  }
  .Sidebar_footer_icon_Youtub{
    color: var(--navColor15);
  }
  .Sidebar_footer_icon_Calling{
    color: var(--navColor7);
  }
  .Sidebar_footer_icon_link:hover {
    transform: scale(1.6);
    text-shadow: 2px 2px 2px var(--navColor7);
  }
  .Sidebar_footer_icon_iNSTAGRAN{
    color: var(--navColor81);
    /* text-shadow: 2px 2px 2px var(--navColor7); */

  }
  .Slide_feb__icon{
    color: var(--navColor74);
  
  }
  .book_now_button {
    /* background-color: #333; */
    /* font-size: 16px; */
   border:  2px solid var(--navColor7);
    text-decoration: none;
    color: var(--navColor7);
    /* line-height: 50px;  */
    /* Vertically center text in button */
    
   
  }
  
  .book_now_button:hover {
    background-color: var(--navColor56); /* Hover effect for the button */
  }
  
  @media (max-width: 768px) {
    .Sidebar_footer_icon {
      flex-direction: column; /* Change to column layout */
      bottom: 10px; /* Keep it fixed to the bottom */
      right: 10px; /* Keep it aligned to the right */
      width: auto; /* Don't take full width */
      /* background-color: transparent; */
       /* Remove background color if not needed */
    }
  
    .Sidebar_footer_icon_link {
      padding: 10px;
      font-size: 18px;
      margin-bottom: 10px; /* Add space between icons */
    }
  }
  
  /* For screens smaller than 450px, revert to a vertical column layout */
  @media (max-width: 450px) {
    .Sidebar_footer_icon {
      flex-direction: column; /* Change to column layout */
      /* bottom: 10px; */
      right: 10px; /* Keep it aligned to the right */
      width: auto; /* Don't take full width */
      /* height: 400px; */
      gap: 2px;
      /* border: 2px solid black; */
      margin-bottom: 0px;
   
      
    }
  
    .Sidebar_footer_icon_link {
      /* padding: 10px; */
      font-size: 18px;
      /* border: 2px solid black; */
      margin-bottom: 0px;
      padding: 2px;
      /* margin-bottom: 10px;  */
      /* Add space between icons */
    }
  }
  
  