.admin-logo {
  height: 50px;
  width: 50px;
  border-radius: 16px;
}

.sidebar-massager-container {
  position: absolute;
  overflow: hidden;
  top: 5px;
  left: 10px;
  bottom: 5px;
  width: 64px; 
  border-radius: 16px;
  background: var(--navColor82);
  will-change: width;
  transition: width 0.4s;
  z-index: 100; 
}

.sidebar-massager-container:hover {
  width: 230px; 
}

.inner-massager-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
}

.header-massager-container {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0 7px;
  background: var(--navShadowColor9);
}

.header-massager-container h1 {
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  color: var(--navColor6);
  transition: opacity 0.5s;
  font-family: var(--firstChoiceFont);
}

.menu-massager-container {
  flex-grow: 1;
  position: relative;
}

.menu-massager-container button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 15px;
  color: var(--navColor83);
  transition: 0.5s;
  background-color: var(--navColor84);
  font-family: var(--firstChoiceFont);

}

.menu-massager-container button:hover > span {
  opacity: 1; 
}

.menu-massager-container :is(button:hover, .active) {
  background: var(--navColor84);
  color: var(--navColor6);
  opacity: 1;
}

.menu-massager-container button p,
.header-massager-container h1 {
  opacity: 0;
  transition: 0.5s;
  font-family: var(--firstChoiceFont);

}

.sidebar-massager-container:hover :is(.menu-massager-container button p, .header-massager-container h1) {
  opacity: 1; 
}

.submenu-massager-container {
  display: none; /* Initially hidden */
}

.menu-item-massager-container:hover .submenu-massager-container {
  display: flex; /* Show submenu on hover */
  flex-direction: column;
  padding-left: 0; 
}

.submenu-massager-container button {
  padding: 10px 20px;
  font-size: 16px; 
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: var(--firstChoiceFont);

}

.massager-submenu-icon {
  min-width: 24px; /* Ensures icon is left-aligned, independent of text length */
  text-align: left; /* Align icon left */
  padding-left: 20px;
  margin-right: 5px; 
}

.submenu-massager-container button p {
  margin-left: 10px;
}

/* Footer */
.sidebar-footer-massager-container {
  display: flex;
  flex-direction: column;
  margin-top: auto; 
}

.footer-massager-button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 15px;
  color: var(--navColor83);
  transition: 0.5s;
  background-color: var(--navColor84); 
  font-family: var(--firstChoiceFont);

}

.footer-massager-button:hover {
  background: var(--navColor85); 
  color: var(--navColor6);
}

.footer-massager-button span {
  font-size: 20px;
}

.footer-massager-button p {
  opacity: 0;
  transition: 0.5s;
  font-family: var(--firstChoiceFont);

}

.sidebar-massager-container:hover .footer-massager-button p {
  opacity: 1;
}

/* Dropdown icon style */
.dropdown-icon {
  margin-left: auto;
  transition: 0.5s;
}

.menu-item-massager-container:hover .dropdown-icon {
  transform: rotate(180deg); /* Rotate the icon on hover for a dropdown effect */
}


/* Notification */
.notification-btn {
  position: relative;
}

.notification-icon {
  font-size: 1.5rem;
}

.Header_Slider{
  position: sticky;
  top: 0px;
  z-index: 5;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 0.7rem;
  padding: 4px 6px;
}

.notification-modal {
  max-width: 500px;
  width: 100%;
  margin-left: 8em;
  font-family: var(--firstChoiceFont);

}

.notification-modal .modal-header {
  background-color: #007bff;
  color: #fff;
}

.notification-modal ul {
  list-style-type: none;
  padding: 0;
}
@media (max-width: 576px) {
  .notification-modal .modal-dialog {
   margin-left: 0px !important;
    margin-right: 8em;
  }
}