.custom-spinner {
    width: 1rem;  /* Adjust the size of the spinner */
    height: 1rem; /* Adjust the size of the spinner */
    border: 2px solid transparent;
    border-top: 2px solid white; /* Adjust color based on your button background */
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
    /* margin-right: 0.5em; Space between the spinner and the text */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-button {
    display: flex;         /* Use flexbox for alignment */
    align-items: center;   /* Center items vertically */
}

.text-loading {
    margin-left: 0.5em; /* Space between spinner and text */
}

.position-relative {
    position: relative;
  }
  
  .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  

.login-logo {
    height: 60px !important;
    width: 60px !important;
}

.custom-modal-header {
    padding: 4px 5px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-family: var(--firstChoiceFont);

}

.ABCD{
    font-family: var(--firstChoiceFont);

}


.custom-modal-header .login-logo {
    margin-right: auto; /* Pushes the title to the center by taking up space on the left */
}

.custom-modal-header .btn-close {
    position: absolute;
    top: 5px; /* Adjust if needed */
    right: 10px; /* Adjust if needed */
    font-family: var(--firstChoiceFont);

}

.custom-modal-header .modaltitle {
    flex-grow: 1; /* Ensures the title takes up the remaining space */
    text-align: center;
    margin: 0; /* Remove default margins */
    font-family: var(--firstChoiceFont);

}