.loading-container5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}
.adv-treatments-container {
  padding: 0;

  background-color: var(--navColor39);
  /* background-image: url('./Images/AdvanceBg.png'); */
  background-image: url('./Images/AdvanceBg1.jpg');
  /* background-image: url('./Images/AdvanceBg2.png'); */
  /* background-image: url('./Images/AdvanceBg3.jpg'); */
  /* background-image: url('./Images/AdvanceBg4.jpg'); */

  padding-top: 20px; 

}
  
.adv-treatments-heading {
  text-align: center;
  color: var(--navColor40); 
  margin: 0.5em 0 0.5em 0.8em;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 3px 3px 6px var(--navColor7); 
  padding: 0.4em;
  position: relative;
  display: inline-block;
  /* position: sticky; */
  /* top: 50px; */
  /* z-index: 5; */
  font-family: var(--firstChoiceFont);


}
  
  /* Add waving underline effect */
.adv-treatments-heading::after {
  content: "";
  display: block;
  width: 100%;
  height: 5px; 
  background: linear-gradient(90deg, var(--navLinearGradient5) 0%, var(--navColor40) 50%, var(--navLinearGradient5) 100%);
  position: absolute;
  bottom: -10px;
  left: 0;
  background-size: 200% 100%;
  animation: wave 2s infinite;
}
  
  @keyframes wave {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 100%;
  }
}
  
  
.adv-treatments-list {
  margin: 0 auto;
  padding: 0.8em;
}
  
.adv-treatment-fullscreen {
  position: relative;
  height: 70vh;
  overflow: hidden;
  border: 1px solid var(--navColor41); 
  background-color: var(--navShadowColor8);
  margin-bottom: 2em;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  /* Enables 3D transformations */
  /* transform-style: preserve-3d;  */
  /* Sets the distance between the Z plane and the user */
  /* perspective: 1000px; */
  /* transform: translateZ(40px); */
  }



.adv-treatment-fullscreen:hover {

  /* transform: rotateX(10deg) rotateY(10deg) translateY(-10px);  */
  box-shadow: 0px 0px 50px var(--navColor12); 

  /* Image 4 */
  /* box-shadow: 0 25px 25px var(--navColor32);  */
}

.adv-treatment-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}
  
.adv-treatment-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transform: translateZ(-10px);  */

}

.adv-treatment-message {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: var(--navShadowColor7); 
  color: var(--navColor6);
  z-index: 2;
  width: 100%;
  text-align: center;
  height: 12em;
  /* transform: translateZ(10px);  */
  /* Moves the message slightly forward */

}

.adv-icon-container {
  font-size: 2rem;
  margin-bottom: 5px;
  color: var(--navColor42);
  color: var(--navColor6);
  color: var(--navColor43);

}  
.adv-treatment-message h3 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--navColor40);
  text-shadow: 1px 1px 2px var(--navColor6); 
  font-weight: bolder;
  font-family: var(--firstChoiceFont);


}

.adv-treatment-message p {
  margin: 0;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);

}






/********************************************************************************/

/* Media query for screens with a width of 1366px or less */
@media (max-width: 1366px) {
  .adv-treatments-heading {
    font-size: 1.8rem;
    margin: 0.5em 0;
  }

  .adv-treatment-fullscreen {
    height: 60vh;
  }

  .adv-treatment-message {
    padding: 15px;
  }

  .adv-icon-container {
    font-size: 1.5rem;
  }

  .adv-treatment-message h3 {
    font-size: 1.3rem;
  }

  .adv-treatment-message p {
    font-size: 0.9rem;
  }
}

/* Media query for screens with a width of 1280px or less */
@media (max-width: 1280px) {
  .adv-treatments-heading {
    font-size: 1.7rem;
    margin: 0.5em 0;
  }

  .adv-treatment-fullscreen {
    height: 55vh;
  }

  .adv-treatment-message {
    padding: 15px;
  }

  .adv-icon-container {
    font-size: 1.4rem;
  }

  .adv-treatment-message h3 {
    font-size: 1.2rem;
  }

  .adv-treatment-message p {
    font-size: 0.9rem;
  }
}
  


/* Media query for screens with a width of 600px or less */
@media (max-width: 600px) {
  .adv-treatments-heading {
    font-size: 1.5em; /* Smaller font size for small screens */
    margin: 1em 0;
  }

  .adv-treatment-fullscreen {
    height: 34vh; /* Reduce height to fit smaller screens */
    margin-bottom: 1em; /* Reduce space between items */
  }

  .adv-treatment-message {
    padding: 8px; /* Less padding for smaller screens */
  }

  .adv-icon-container {
    font-size: 1em; /* Adjust icon size */
  }

  .adv-treatment-message h3 {
    font-size: 1em; /* Adjust heading size */
  }

  .adv-treatment-message p {
    font-size: 0.7em; /* Adjust paragraph size */
  }

  .adv-treatment-image img {
    height: auto; /* Ensure images scale properly */
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
