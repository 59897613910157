/* MassagerListStyles.css */

/* Save Change CSS */
.loading-icon {
  width: 1rem;  /* Adjust the size of the spinner */
  height: 1rem; /* Adjust the size of the spinner */
  margin-right: 0.5em; /* Space between spinner and text */
}

.action-save-button {
  display: flex;         /* Use flexbox for alignment */
  align-items: center;   /* Center items vertically */
}

.status-text {
  margin-left: 0.5em; /* Space between spinner and text */
}


.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Center spinner vertically and horizontally */
}

/* Container for the table */
.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto; /* Horizontal scroll for very narrow screens */
}

/* Table styles */
.mresponsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Table headers and cells */
.mresponsive-table th {
  padding: 12px;
  border-bottom: 1px solid var(--navColor41);
  font-family: var(--firstChoiceFont);
}
.mresponsive-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--navColor41);
  font-family: var(--firstChoiceFont);
}

.mresponsive-table th {
  font-size: 1rem;
}

/* Table header background */
.responsive-table th {
  background-color: var(--navColor98);
}

/* Dropdown styles */
.massager-dropdown {
  padding: 10px;
  border: 1px solid var(--navColor94);
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box; /* Ensures dropdown takes full width */
  font-family: var(--firstChoiceFont);
}

.massager-dropdown:hover {
  border-color: var(--navColor99);
}

.hand-action-icon{
  cursor: pointer;
}

.massager-dropdown:focus {
  outline: none;
  border-color: var(--navColor87);
}

.Massager-list-edit-form{
  background-color: var(--navColor27);
}

/* Button styles */
.responsive-table .btn {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);
}

/* Media Query for Tablets (Up to 768px) */
/* @media (max-width: 768px) {
    .responsive-table th, 
    .responsive-table td {
      padding: 8px;
      font-size: 0.9rem;
    }
  
    .massager-dropdown {
      font-size: 0.9rem;
    }
  } */

/* Media Query for Mobile Devices (Up to 480px) */
/* @media (max-width: 480px) {
    .responsive-table thead {
      display: none; 
    }
  
    .responsive-table tr {
      display: block;
      margin-bottom: 15px;
      border-bottom: 2px solid #ddd;
    }
  
    .responsive-table td {
      display: block;
      text-align: right;
      padding-left: 70%;
      position: relative;
      border: none;
      border-bottom: 1px solid #eee;
    }
  
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 15px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
      font-size: 0.9rem;
    }
  
    .massager-dropdown {
      width: 100%;
      font-size: 0.85rem;
      padding: 8px;
    }
  
    
    .responsive-table .btn {
      padding: 6px 10px;
      font-size: 0.9rem;
    }
  } */
