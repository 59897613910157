.Massager_Registraction_form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--navColor91);
    box-shadow: 0 4px 8px var(--navShadowColor);
    border-radius: 8px;
  }
  
  .Massager_Registraction_title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: var(--firstChoiceFont);
    background-color: var(--navColor98);

  }
  
  .Massager_Registraction_field {
    margin-bottom: 15px;
  }
  
  .Massager_Registraction_field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: var(--firstChoiceFont);

  }
  
  .Massager_Registraction_field input,
  .Massager_Registraction_field select {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--navColor94);
    border-radius: 4px;
    font-family: var(--firstChoiceFont);

  }
  
  .Massager_Registraction_error {
    color: var(--navColor15);
    font-size: 0.9rem;
    font-family: var(--firstChoiceFont);

  }
  
  .Massager_Registraction_submit {
    width: 100%;
    padding: 10px;
    background-color: var(--navColor92);
    color: var(--navColor6);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--firstChoiceFont);

  }
  
  .Massager_Registraction_submit:hover {
    background-color: var(--navColor93);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .Massager_Registraction_form {
      padding: 15px;
    }
  
    .Massager_Registraction_title {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .Massager_Registraction_form {
      padding: 10px;
    }
  
    .Massager_Registraction_title {
      font-size: 18px;
    }
  }
  

  .form-group-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .form-group-inline {
    width: 48%;
  }
  
  .error {
    color: red;
    font-size: 0.9em;
  }
  