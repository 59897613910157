.loading-containerr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust based on your layout */
}

/* General Container Styles */
.Contact_Us_container {
  /* font-family: Arial, sans-serif; */
  text-align: center;
  /* padding: 20px; */
  box-sizing: border-box;
  width: 100%;
}

/* Header Section */
.Contact_Us_header {
  background-image: url('./miryam-leon-Jdpvhn6xA0w-unsplash_11zon.jpg');

  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px 20px;
  color: var(--navColor6);
  min-height: 100vh;
  width: 100%;
}

.Contact_Us_title {
  font-size: 3rem;
  /* padding-top: 12%; */
  margin: 3em 0 1em 0;
  display: inline-block;
  color: var(--navColor50);
  font-weight: bolder;
  text-shadow: 2px 2px 2px var(--navColor6);
  font-family: var(--firstChoiceFont);
  transform: translateY(20px);
  animation: fadeIn 1s forwards;

}

.Contact_Us_title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  /* Slightly below the text */
  left: 0;
  width: 0;
  height: 4px;
  /* Thickness of the underline */
  background-color: var(--navColor39);
  /* Green shade for underline */
  transition: width 0.5s ease-out;
  /* Smooth transition effect */
}

.Contact_Us_title:hover::after {
  width: 100%;
  /* Expands underline to match heading width */
}

.Contact_Us_description {
  font-size: 1.3em;
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  font-weight: bold;
  margin-right: auto;
  line-height: 1.5;
  color: var(--navColor7);
  text-shadow: 2px 2px 2px var(--navColor6);
  /* font-family: 'Georgia', serif; */
  font-family: var(--firstChoiceFont);
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;

}

.Contact_Us_description:hover {
  transform: scale(1.1) !important;
}

/* Content Section: Flexbox Layout */
.Contact_Us_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  /* This allows elements to stack on smaller screens */
  padding: 40px 0;
  gap: 20px;
  min-height: 100vh;
}

.Contact_Us_left,
.Contact_Us_right {
  flex: 1;
  padding: 20px;
  min-width: 300px;
  /* Ensures each column doesn't get too narrow on small screens */
  /* border: 2px solid var(--navColor7); */
  width: 100px;
  /* text-align: left; */

}

.Contact_Us_left,
.Contact_Us_right {
  /* border: 2px solid var(--navColor7); */
  text-align: center;

}

.icon_Contact_Add {
  color: black;
}

.icon_contact_phone {
  margin: 2px;
}

.Contact_us_right_child {
  /* border: 2px solid var(--navColor7); */

  background-color: var(--navColor70) !important;
  color: var(--navColor7) !important;
  width: 70%;
  height: 100%;
  padding-top: 10% !important;
  padding-bottom: 10% !important;
  border-radius: 10% !important;
  padding-left: 10% !important;
  text-align: left !important;
}

.Contact_us_right_child h1 {
  margin-left: 40px;
}

.Contact_us_left_child {
  width: 70%;
  margin-left: 30%;
  animation: slideInLeft 5s ease forwards;
  /* Increased duration to 5s */
  animation-delay: 0.3s;
  animation-timeline: scroll();
  animation-range: entry 1;
}

.Contact_Us_right {
  animation: slideInRight 5s ease forwards;
  /* Increased duration to 5s */
  animation-delay: 0.3s;
  animation-timeline: scroll();
  animation-range: entry 1;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}



.Contact_Us_subtitle {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--navColor71);
  /* text-shadow: 1px 1px 3px #00000066; */
  /* padding-left: 30%;    */
  text-transform: uppercase;
  font-family: var(--firstChoiceFont);

}

.Contact_Us_map {
  width: 100%;
  max-width: 100%;
  /* Make sure the map is always responsive */
  height: 300px;
  border: none;
}

.Contact_Us_address,
.Contact_Us_phone,
.Contact_Us_Email,
.Contact_Us_YouTub {
  /* text-shadow: 2px 2px 2px var(--navColor7); */
  font-size: 1.2rem;
  /* color: var(--navColor6); */
  margin-top: 10px;
  font-family: var(--firstChoiceFont);
  padding: 0 0.5em;

}

.Contact_Us_phone a {
  /* color : var(--navColor6); */
  color: var(--navColor7);
  text-decoration: none;
}

.Contact_Us_Email a {
  /* color: var(--navColor6); */
  color: var(--navColor7);
  text-decoration: none;
}

.Contact_Us_YouTub a {
  color: var(--navColor72);
  text-decoration: none;
}

.Contact_Us_phone a:hover {
  text-decoration: underline;
}

.Contact_Us_map:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px var(--navShadowColor1);
}

/* Responsive Adjustments */

/* For large desktops */
@media (max-width: 1200px) {
  .Contact_Us_title {
    font-size: 2.5rem;
  }

  .Contact_Us_description {
    font-size: 1.1rem;
  }

  .Contact_Us_subtitle {
    font-size: 1.4rem;
  }

  .Contact_Us_map {
    height: 250px;
  }

}

/* For tablets */
@media (max-width: 768px) {
  .Contact_Us_title {
    font-size: 2rem;
  }

  .Contact_Us_description {
    font-size: 1rem;
  }

  .Contact_Us_subtitle {
    font-size: 1.3rem;
  }

  .Contact_Us_content {
    flex-direction: column;
    /* Stack items on top of each other on smaller screens */
    align-items: center;
  }

  .Contact_Us_map {
    height: 200px;
  }

  .Contact_Us_left,
  .Contact_Us_right {

    min-width: 100%;
    /* Make both sections full-width */
  }

  .Contact_us_right_child {
    width: 100%;
  }

  .Contact_us_left_child {

    width: 100%;
    margin-left: 0px;
  }


}

/* For mobile devices */
@media (max-width: 420px) {

  .Contact_Us_title {
    padding-top: 30%;
  }

  .Contact_Us_description {
    font-size: 0.9rem;
    padding-top: 20px;

  }

  .Contact_Us_subtitle {
    font-size: 1.2rem;
  }

  .Contact_Us_map {
    height: 150px;
  }

  .Contact_Us_address,
  .Contact_Us_phone {
    font-size: 1rem;
  }

  /* Reduce padding for better spacing on smaller screens */
  .Contact_Us_header,
  .Contact_Us_content {
    padding: 20px 10px;
  }

  /* Make map and contact info stack on top of each other */
  .Contact_Us_content {
    flex-direction: column;
  }

  /* Reduce overall margins to make more space */
  .Contact_Us_description {
    margin: 10px 0;
    text-align: justify;
    text-align: center;
    color: var(--navColor7);
    text-shadow: none;

  }

  .Contact_us_right_child {
    width: 100%;
    padding: 0%;
    background-color: var(--navColor6);
  }

  .Contact_us_left_child {
    width: 100%;
    margin-left: 0px;
  }

}

/* For mobile devices */
@media (max-width: 480px) {
  @keyframes slideInLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}