.Massagercardborder {
  border-left: 8px solid var(--navColor101) !important;
  border-bottom: 10px solid var(--navColor101) !important;
  border-radius: 20px !important;
  transition: transform 0.2s ease; /* Smooth transition */
  font-family: var(--firstChoiceFont);
}

.Massagercardborder4 {
  border-left: 8px solid var(--navColor102) !important;
  border-bottom: 10px solid var(--navColor102) !important;
  border-radius: 20px !important;
  transition: transform 0.2s ease; /* Smooth transition */
  font-family: var(--firstChoiceFont);
}

.Massagercardborder2 {
  border-left: 8px solid var(--navColor103) !important;
  border-bottom: 10px solid var(--navColor103) !important;
  border-radius: 20px !important;
  transition: transform 0.2s ease; /* Smooth transition */
  font-family: var(--firstChoiceFont);
}

.Massagercardborder3 {
  border-left: 8px solid var(--navColor104) !important;
  border-bottom: 10px solid var(--navColor104) !important;
  border-radius: 20px !important;
  transition: transform 0.2s ease; /* Smooth transition */
  font-family: var(--firstChoiceFont);
}

.cardborderMassager1 {
  border-left: 8px solid transparent !important; /* Set to transparent to avoid visual issues */
  border-bottom: 10px solid var(--navColor101) !important;
  border-radius: 20px !important;
  transition: transform 0.2s ease; /* Smooth transition */
  font-family: var(--firstChoiceFont);
}

/* Hover effects */
.Massagercardborder:hover,
.Massagercardborder4:hover,
.Massagercardborder2:hover,
.Massagercardborder3:hover,
.cardborderMassager1:hover {
  transform: scale(1.05); /* Scale effect */
  box-shadow: 0 4px 15px var(--navShadowColor1); /* Optional shadow effect */
}
.text-gray-custom {
  color: var(--navColor105); /* This is Bootstrap's standard gray */
}

/* If you want a different shade, you can adjust the hex code */
.text-gray-light {
  color: var(--navColor106); /* A lighter gray */
}

.text-gray-dark {
  color: var(--navColor49); /* A darker gray */
}






.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3); /* Light background */
  border-top: 8px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 60px; /* Size of the spinner */
  height: 60px; /* Size of the spinner */
  animation: spin 1s linear infinite;
   /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(100deg); }
}



