/* MassagerReport.css */

/* .loader-container20 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  text-align: center;
  padding: 20px; 
} */

/* MassagerReport.css */

/* Loader styling */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-left-color: #4a90e2; /* Spinner color */
  border-radius: 50%;
  width: 50px; /* Spinner size */
  height: 50px; /* Spinner size */
  animation: spin 1s linear infinite; /* Rotation animation */
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}


.MassagerReport_container {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--firstChoiceFont);

}

.Report_Massager_Page_downloadGif {
  cursor: pointer;
  width: 50px; /* Adjust size as per requirement */
  height: auto;
}
.MassagerReport_heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.MassagerReport_dateSelectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.MassagerReport_input {
  padding: 8px;
  margin-left: 10px;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);

}

.MassagerReport_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.MassagerReport_table th, 
.MassagerReport_table td {
  border-bottom: 1px solid var(--navColor41);
  padding: 8px;
  text-align: left;
  font-family: var(--firstChoiceFont);

}

.MassagerReport_table th {
  background-color: var(--navColor96);
}

.MassagerReport_buttonContainer {
  text-align: center;
}

.MassagerReport_button {
  padding: 10px 20px;
  background-color: var(--navColor59);
  color: var(--navColor6);
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.MassagerReport_button:hover {
  background-color: var(--navColor95);
}

/* Responsive Design */
@media (max-width: 768px) {
  .MassagerReport_dateSelectors {
      flex-direction: column;
  }

  .MassagerReport_input {
      width: 100%;
      margin-bottom: 10px;
  }

  .MassagerReport_button {
      width: 100%;
  }
}
