/* Define keyframes for scaling and rotating */
@keyframes scaleRotate {
  0% {
    transform: scale(0.9) rotate(0deg);
  }

  50% {
    transform: scale(1.2) rotate(0deg);
  }

  100% {
    transform: scale(0.9) rotate(0deg);
  }
}

/* Logo styles */
.nav-logo {
  width: 80px;
  /* Ensure width and height are equal for a circle */
  height: 60px;
  /* Ensure width and height are equal for a circle */
  border-radius: 10%;
  margin-right: 20px;
  object-fit: cover;
  /* Cover ensures the image fills the circle */
  animation: scaleRotate 5s ease-in-out infinite;
  /* Apply animation */
  filter: contrast(154%);
  background-color: var(--navColor7);
}

/* Navbar panel styles */
.nav-panel {
  background-color: var(--navColor1);
  padding: 5px 10px !important;
  margin: 0 auto !important;
  box-shadow: 0 4px 8px var(--navShadowColor);
  width: 100%;
  /* position: fixed !important;
  top: 0 !important; */
  z-index: 1000;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  /* Space out brand and links */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for Navbar panel */
.nav-panel:hover {
  background-color: var(--navColor2);
  border: 0.1px solid var(--navColor3);
  box-shadow: 0 4px 8px var(--navShadowColor1);
}

/* Navbar brand styles */
.navbar-brand-custom {
  display: flex;
  align-items: center;
}

/* Nav links container styles */
.nav-links-container {
  flex-grow: 1 !important;
  /* Allows the nav links container to take up available space */
}

/* Nav links styles */
.nav-links1 {
  display: flex !important;
  justify-content: center !important;

}

/* Nav link styles */
.nav-link1 {
  color: var(--navlinkColor) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-decoration: none !important;
  border-radius: 5px !important;
  padding: 1em !important;
  position: relative !important;
  /* Position relative for the dropdown */
  transition: color 0.3s ease;
  display: flex !important;
  /* Apply flex to keep text and arrow aligned */
  align-items: center !important;
  /* Vertically center the text and arrow */
  justify-content: space-between !important;
  /* Align text and arrow to the center */

  font-family: var(--firstChoiceFont);
}

.nav-link1:hover {
  color: var(--navColor4) !important;
}

.nav-link-ourtreatment-dropdown {
  color: var(--navlinkColor) !important;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  padding: 0.6em;
  font-family: var(--firstChoiceFont);

}

.nav-link-ourtreatment-dropdown:hover {
  color: var(--navColor4) !important;

}

.active-link {
  color: var(--navColor4) !important;
  /* Change to your desired active color */
  font-weight: bold;
  /* Optional: bold font for active link */
}


/* Login button styles */
.log-in-button {
  background: linear-gradient(145deg, var(--navLinearGradient), var(--navColor5)) !important;
  /* Gradient for depth */
  color: var(--navColor6) !important;
  /* Text color */
  font-weight: bold !important;
  /* font-family: var(--firstChoiceFont); */
  border-radius: 30px !important;
  border: none !important;
  box-shadow: 4px 4px 8px var(--navShadowColor2), inset 1px 1px 3px var(--navShadowColor3) !important;
  /* 3D shadow */
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease !important;
  white-space: nowrap !important;
  /* Prevent text from wrapping */
  padding: 10px 25px !important;
  /* Add padding: top-bottom | left-right */
  font-size: 16px !important;
  /* Adjust font size */
  text-transform: uppercase !important;
  /* Uppercase text for emphasis */
  cursor: pointer !important;
  /* Change cursor to pointer */
  display: inline-block !important;
  /* Make sure button is inline */
  text-align: center !important;
  /* Center text */
}

/* Login button hover effect */
.log-in-button:hover {
  background: linear-gradient(145deg, var(--navLinearGradient1), var(--navLinearGradient2));
  /* Darker gradient for hover effect */
  box-shadow: 6px 6px 12px var(--navShadowColor2), inset 2px 2px 6px var(--navShadowColor3);
  /* Enhanced shadow on hover */
  transform: translateY(-2px) !important;
  /* Lift button up on hover */
}

/* Login button active effect */
.log-in-button:active {
  background: linear-gradient(145deg, var(--navLinearGradient3), var(--navLinearGradient4)) !important;
  /* Even darker gradient when pressed */
  box-shadow: 2px 2px 5px var(--navShadowColor2), inset 1px 1px 2px var(--navShadowColor3) !important;
  /* Adjust shadow when pressed */
  transform: translateY(1px) !important;
  /* Button appears pressed down */
}

/* Custom Toggler for small screens */
.custom-toggler {
  border: none;
  background-color: transparent;
}

.basic-navdropdown {
  margin: 0 0 0 -15px;
  display: flex;
  /* Use flex to align items */
  align-items: center;
  /* Vertically center items */

}

.nav-dropdown-item {

  color: var(--navColor7);
  font-weight: bold;
  padding: 5px 10px;
  transition: background 0.3s ease, color 0.3s ease;
  display: flex;
  /* Make dropdown items flex containers */
  align-items: center;
  /* Center icons and text */
}

.nav-dropdown-item:hover {
  background-color: var(--navColor8) !important;
  color: var(--navColor5) !important;
  /* border-radius: 10px; */
  /* border: 1px solid hotpink; */
  text-decoration: underline;
}

.nav-dropdown-item i {
  margin-right: 8px;
  /* Space between icon and text */
}

.nav-dropdown-item:focus {
  outline: none;
  background-color: var(--navColor9);
  color: var(--navColor5);
}

.navbar-nav .dropdown-menu {
  background-color: var(--navColor2) !important;
  border: none;
}

@media screen and (max-width: 1024px) {

  /* .nav-link1 {
    font-size: 10px !important;
  } */
  .nav-link {
    font-size: 10px !important;
  }
}