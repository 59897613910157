/* Flexbox layout for sidebar and content */
.massager-panel {
  display: flex;
  height: 100vh; /* Full viewport height */
  /* border: 50px solid red ! important; */
  }
  
  
  /* Sidebar should remain a fixed width */
  .sidebar-massager-container{
     /* flex: 0 0 220px;  */
     position: relative;
     z-index: 100;
  }
  
  /* Content section takes up the remaining space */
  .Massager-content {
    flex: 1;
    padding: 20px; /* Add some padding for the main content */
    background-color: var(--navColor37); /* Light background for contrast */
    /* overflow-y: auto; Allow scrolling if the content overflows */
    overflow-x: auto;
    
  }
  
  /* Main content adjustments */
  main {
    padding: 5px;;
    background: var(--navColor6);
    border: 10px solid  var(--navColor100);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--navShadowColor7);
    min-height: 100%;
    overflow-x: auto;
       
  }
  
  .marquee-container {
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text wrapping */
    width: 100%; /* Full width */
    position: relative; /* Position for absolute children */
  }
  
  .marquee-text {
    display: inline-block; /* Inline block for animation */
    padding-left: 100%; /* Start from the right */
    animation: marquee 25s linear infinite; /* Marquee animation */
    font-size: 24px; /* Adjust size as needed */
    font-weight: bolder;
    color: var(--navColor36); /* Text color */
    transform: perspective(500px) rotateY(10deg); /* 3D effect */
    text-shadow: 2px 2px 5px var(--navShadowColor11);
    
  }
  
  /* Marquee animation */
  @keyframes marquee {
    0% {
      transform: translate(0, 0); /* Start position */
    }
    100% {
      transform: translate(-100%, 0); /* End position */
    }
  }
  
  