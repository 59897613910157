/* src/Toaster.css */
@keyframes slideIn {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

.SuccessToaster{
    animation: slideIn 0.5s forwards;
    z-index: 100000;
    padding: 10px !;
}

.SuccessToaster.hide {
    animation: slideOut 0.5s forwards;
}

.SuccessToasterHeader{
    color: var(--navColor7);
    font-family: var(--firstChoiceFont);

}
.SuccessToasterBody{
    color: var(--navColor6);
    font-family: var(--firstChoiceFont);

}
