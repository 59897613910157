/* Sidebar Styles */
.adminlogo{
  height: 50px;
  width: 50px;
  border-radius: 16px;
}
.sidebar {
  position: absolute;
  overflow: hidden;
  top: 5px;
  left: 10px;
  bottom: 5px;
  width: 64px; 
  border-radius: 16px;
  background: var(--navColor82);
  will-change: width;
  transition: width 0.4s;
  z-index: 100; 
}

.sidebar:hover {
  width: 230px; 
}

.inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.header {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0 7px;
  background: var(--navShadowColor9);
}

.header h1 {
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  color: var(--navColor6);
  transition: opacity 0.5s;
  font-family: var(--firstChoiceFont);
}

.menu {
  flex-grow: 1;
  position: relative;
}

.menu button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 15px;
  color: var(--navColor83);
  transition: 0.5s;
  background-color: var(--navColor84);
}

.menu button:hover > span {
  opacity: 1; 
}

.menu :is(button:hover, .active) {
  background: var(--navColor84);
  color: var(--navColor6);
  opacity: 1;
}

.menu button p,
.header h1 {
  opacity: 0;
  transition: 0.5s;
  font-family: var(--firstChoiceFont);

}

.sidebar:hover :is(.menu button p, .header h1) {
  opacity: 1; 
}

.submenu {
  display: none; /* Initially hidden */
}

.menu-item:hover .submenu {
  display: flex; /* Show submenu on hover */
  flex-direction: column;
  padding-left: 20px; 
}

.submenu button {
  padding: 10px 20px;
  font-size: 16px; 
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: var(--firstChoiceFont);
}

.submenu-icon {
  margin-left: auto;
}

.submenu button p {
  margin-left: 10px;
}

/* Footer */
.sidebar-footer {
  display: flex;
  flex-direction: column;
  margin-top: auto; 
}

.footer-btn {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 15px;
  color: var(--navColor83);
  transition: 0.5s;
  background-color: var(--navColor84); 
  font-family: var(--firstChoiceFont);

}

.footer-btn:hover {
  background: var(--navColor85); 
  color: var(--navColor6);
}

.footer-btn span {
  font-size: 20px;
  /* font-family: var(--firstChoiceFont); */

}

.footer-btn p {
  opacity: 0;
  transition: 0.5s;
  font-family: var(--firstChoiceFont);

}

.sidebar:hover .footer-btn p {
  opacity: 1;
}


/* Dropdown icon style */
.dropdown-icon {
  margin-left: auto;
  transition: 0.5s;
}

.menu-item:hover .dropdown-icon {
  transform: rotate(180deg); /* Rotate the icon on hover for a dropdown effect */
}


.submenu {
  padding-left: 20px; /* Indent submenu from parent */
}

.submenu button {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align submenu items to the left */
  padding: 8px 16px;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
}

.submenu-icon {
  margin-right: 5px; /* Space between icon and text */
  min-width: 24px; /* Ensures icon is left-aligned, independent of text length */
  text-align: left; /* Align icon left */
}

.submenu p {
  margin: 0; /* Remove default margin */
  flex-grow: 1; /* Allow text to take remaining space */
}

.notification-btn {
  position: relative;
}

.notification-icon {
  font-size: 1.5rem;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 0.7rem;
  padding: 4px 6px;
}



.notification-modal {
  margin-top: 8em;
  margin-right: 2em;
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  margin-left: 8em;
  font-family: var(--firstChoiceFont);
  /* overflow: hidden; */
}

.notification-modal .modal-header {
  background-color: #007bff;
  color: #fff;
}

.notification-modal ul {
  list-style-type: none;
  padding: 0;
}

.notification-modal li {
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
}

.notification-modal li.read {
 
  font-weight: bold;
}

.notification-modal li.unread {
  font-weight: bold; /* Bold for unread notifications */
}

.modal-dialog{
  margin: auto;
}

@media (max-width: 576px) {
  .notification-modal .modal-dialog {
    margin-left: 0px !important;
    margin-right: 8em;
  }
}