.loader-container10 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  flex-direction: column;
}

.bgcertificate {
  background-color: var(--navShadowColor6);
}

.certificatecontainer {
  padding: 3em;
}

.Certificate_page_header {
  background-image: url("./AdvanceBg31.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px 20px;
  /* color: var(--navColor6); */
  min-height: 100vh;
  width: 100%;
}

.cerheadinfo {
  margin: 0 20%;
  text-align: center;
  font-weight: bolder;
  font-size: 1.5em;
  color: var(--navColor91);
  text-shadow: 2px 2px 4px var(--navShadowColor7);
  font-family: var(--firstChoiceFont);
  /* width: 50%; */
}

.cerheadinfo:hover {
  transform: scale(1.1);
}

.certificate-wrapper {
  border: 2px solid var(--navColor59);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: var(--navColor6);
  box-shadow: 0 4px 12px var(--navShadowColor9);
  margin: 0.5em;
  text-align: center;
}

/* .certificate_heading1 {
  font-size: 3em;
  font-weight: bold;
  font-family: var(--firstChoiceFont);
  display: inline-block;
  color: var(--navColor40);
  margin-bottom: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px var(--navShadowColor2);
  animation: fadeIn 1s;
} */

.Second-quote {
  color: var(--navColor30);
  /* margin-top: 1em; */
}

.certificate_heading {
  font-size: 3.5em;
  font-weight: bold;
  font-family: var(--firstChoiceFont);
  /* display: inline-block; */
  text-align: center;
  color: var(--navColor40);
  /* border: 2px solid red; */
  position: relative;
  margin: 2.5em 0 1.2em 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px var(--navShadowColor2);
  animation: fadeIn 1s;
}

.certificate_heading::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 22%;
  width: 0;
  height: 4px;
  background-color: var(--navColor40);
  transition: width 0.5s ease-out;
}

.certificate_heading:hover::after {
  width: 55%;
}

/* Optional keyframes for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.certificate-image {
  width: 100%;
  height: auto;
  max-height: 470px;
  object-fit: cover;
  border-bottom: 2px solid var(--navColor59);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.certificate-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px var(--navShadowColor1);
}

.certificate-image.portrait {
  max-height: none;
  height: auto;
  object-fit: contain;
  background: transparent;
  box-shadow: none;
}

.certificate-info {
  padding: 1em;
  background-color: var(--navColor44);
  border-top: 1px solid var(--navColor59);
  color: var(--navColor6);
  text-align: center;
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0 1px 3px var(--navShadowColor1);
}

.certificate-info h5 {
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
  font-family: var(--firstChoiceFont);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certificate-info p {
  margin: 0.5em 0 0;
  font-size: 0.9em;
  font-family: var(--firstChoiceFont);
}

/******************Media Query 900****************************************/

@media (max-width: 900px) {
  .certificate_heading,
  .certificate_heading1 {
    font-size: 2.5em;
  }

  .certificate_heading {
    margin-top: 2em;
  }

  .cerheadinfo {
    font-size: 1.3em;
  }

  .certificate_info h5 {
    height: 40px;
    /* font-size: 1em !important;  */
    /* color: aquas; */
  }

  .certificate-info-h5 {
    font-size: 1em !important;
  }
}

/******************Media Query 768****************************************/
@media (max-width: 768px) {
  /* Container adjustments */
  .certificatecontainer {
    padding: 2em;
  }

  /* Heading adjustments */
  .certificate_heading,
  .certificate_heading1 {
    font-size: 2.5em;
    margin-top: 2em;
  }

  /* Subheading and description adjustments */
  .cerheadinfo {
    font-size: 1.3em;
    margin: 0 10%;
  }

  /* Certificate info adjustments */
  .certificate_info h5 {
    height: 40px;
    font-size: 0.5em !important;
  }

  /* Image adjustments */
  .certificate-image {
    /* max-height: 350px; */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  /* Certificate wrapper adjustments */
  .certificate-wrapper {
    margin: 1em;
  }
}

/******************Media Query 600****************************************/

@media (max-width: 600px) {
  .certificate_heading {
    font-size: 1.5em !important;
  }

  .Certificate_page_header {
    background-image: url(mobile_bg.avif);
  }

  .certificate_heading {
    margin-top: 1em !important;
  }

  .cerheadinfo {
    font-size: 1em;
    padding: 0 10%; /* Add padding for better visibility */
  }
  .certificate_info h5 {
    height: 30px; /* Adjust height for smaller screens */
    /* font-size: 0.9em;  */
  }
  .certificate-info-h5 {
    font-size: 0.7em !important;
  }
}





/******************Media Query 425****************************************/

@media (max-width: 425px) {
  .certificate_heading {
    font-size: 1.7em !important;
  }

  .Certificate_page_header {
    background-image: url(mobile_bg.avif);
  }

  .certificate_heading {
    margin-top: 1em !important;
  }

  .cerheadinfo {
    font-size: 1.1em !important;
    text-align: justify;
  }
  .certificate_info h5 {
    height: 25px; /* Minimal height for very small screens */
    font-size: 0.8em; /* Further reduce font size */
  }
}

/******************Media Query 350****************************************/
@media (max-width: 350px) {
  .certificate_heading,
  .certificate_heading1 {
    font-size: 1.5em !important;
    text-align: center !important;
  }

  .certificate_heading {
    text-align: justify;
    /* margin-top: 6em; */
  }

  .certificate-info h5 {
    font-size: 1em;
  }

  .cerheadinfo {
    font-size: 1em !important;
    text-align: justify;
  }

  .certificate_info h5 {
    height: 20px;
    font-size: 0.7em;
  }
}
