.looading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}

.massage-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four cards per row */
  gap: 20px;
  max-width: 801px; /* Adjust max-width to center cards properly */
  margin: 0 auto;
  padding: 10px;
  justify-items: center; /* Centers the cards */
  background-color: var(--navColor62); 
}
.massager-card-background { /* card background color */
  background-color: var(--navColor63); /* Pale Lavender */
}
.massageBg{
 
  background-color: var(--navColor62); 
}
/* Card container for the flip effect */

.massage-flip-card {
  position: relative;
  perspective: 1000px;
  width: 100%;
  padding-bottom: 100%; /* Maintain square aspect ratio */
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out; /* Transition for shadow effect */

  }

.massage-flip-card:hover {
  box-shadow: 0 8px 16px var(--navShadowColor2); /* Shadow on hover */
}

/* Inner content of the card */
.massage-flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s, box-shadow 0.3s ease-in-out;
  transform-style: preserve-3d;
  text-align: center;
  
}

/* Flip card hover effect */
.massage-flip-card:hover .massage-flip-card-inner {
  transform: rotateY(180deg);
  box-shadow: 0 9px 16px var(--navShadowColor10); /* Keep shadow during flip */
}


/* Inner content of the card */
.massage-flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  text-align: center;
}

/* Flip card hover effect */
.massage-flip-card:hover .massage-flip-card-inner {
  transform: rotateY(180deg);
}

/* Front and back card styles */
.massage-flip-card-front,
.massage-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid var(--navColor64);
  border-radius: 10px;
  overflow: hidden;

}

.massage-flip-card-back{
  background-color: var(--navColor65);
}

/* Back side of the card */
.massage-flip-card-back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

/* Circular image container */
.massage-circular-image-container {
  width: 60%; /* Larger image container */
  height: 60%;
  margin: 10px auto;
  padding: 4px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navColor66);
}

/* Image inside the circular container */
.massage-circular-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Card content styles */
.massage-card .card-title {
  font-size: 0.9rem;      /* Adjusted to a smaller size */
  font-weight: bold;
  margin-top: 9px;
  font-family: var(--firstChoiceFont);


  white-space: nowrap;    /* Prevents text wrapping */
  overflow: hidden;       /* Hides overflow text */
  text-align: center;       /* Aligns text to the left */
}


.massage-card .card-text {
  font-size: 1rem;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

/* Back of the card content */
.massage-flip-card-back .massage-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem; /* Larger text on back of card ***************************/
  /* color: rgb(72, 141, 72); */
  font-weight: bold;
  font-family: var(--firstChoiceFont);

}

/* Header styles */
.massage-header {
  background-image: url("./ImageHeader.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px;
  margin-bottom: 10px;
}

.massage-h1 {
  position: relative;
  display: inline-block; 
  /* Restricts underline to the heading's width */
  text-align: center;
  font-size: 45px;
  margin-top: 50px;
  margin-bottom: 20px; /* Adjusted for better spacing */
  /* font-family: 'Lucida Calligraphy'; */
  font-family: var(--firstChoiceFont);
  color: var(--navColor67);
}

.massage-h1::after {
  content: '';
  position: absolute;
  bottom: -5px; /* Slightly below the text */
  left: 0;
  width: 0;
  height: 4px; /* Thickness of the underline */
  background-color: var(--navColor68) ; /* Green shade for underline */
  transition: width 0.5s ease-out; /* Smooth transition effect */
}

.massage-h1:hover::after {
  width: 100%; /* Expands underline to match heading width */
}

.massage-h1:hover::after {
  width: 100%; /* Full width underline on hover */
}

/* Responsive styling for smaller screens */

@media (max-width: 1200px) {
  .massage-card-grid {
    max-width: 120%; /* Ensures the grid takes the full width of its parent container. */
    gap: 15px; /* Adds 15 pixels of space between grid items for better spacing. */
  }

  .massage-circular-image-container {
    width: 73%; /* Sets the width of the image container to 65% of its parent, adjusting for medium screens. */
    height: auto; /* Automatically adjusts height to maintain aspect ratio of the image. */
    margin: 4px auto;
  }

  .massage-card .card-title {
    font-size: 0.9rem; /* Sets the font size of the card title to 1 rem, making it readable on medium screens. */
    font-weight: bold; /* Makes the title bold for emphasis and clarity. */
  }

  .massage-card .card-text {
    font-size: 0.9rem; /* Sets the font size of the card text to 0.9 rem for good readability. */
    font-weight: bold; /* Makes the card text bold, ensuring it stands out. */
  }
}







@media (max-width: 770px) {
  .massage-card-grid {
    position: relative; /* Allows absolute positioning of cards inside. */
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four equal columns. */
    max-width: 85%; /* Adjusts width to fit slightly larger screens. */
    gap: 15px; /* Space between grid items. */
  }

  .massage-card {
    position: relative; /* Allows the card to be positioned relative to its normal position. */
    z-index: 1; /* Ensures the card appears above the background content. */
    transition: transform 0.3s; /* Adds a smooth transition effect. */
  }

  .massage-card:hover {
    transform: translateY(-10px); /* Moves the card up on hover for a lift effect. */
    z-index: 2; /* Brings the hovered card above others. */
  }

  .massage-circular-image-container {
    width: 50%; /* Sets width to 70% of the parent. */
    height: auto; /* Maintains aspect ratio. */
    margin: 3px auto; /* Centers the image container. */
    
  }

  .massage-card .card-title {
    font-size: 0.7rem; /* Adjusts title size for readability. */
    font-weight: bold; /* Makes the title bold. */
    /* color: red; */

  }

.massage-card .card-text {
    font-size: 2px; /* Increased text size for better readability. */
    font-weight: bold; /* Apply bold weight to the text. */
    line-height: 0.9rem;
}

  .massage-flip-card-back .massage-body {
    font-size: 0.50rem; /* Adjusted size for the back of the card. */
    font-weight: bold; /* Normal weight. */
  }

  .massage-h1 {
    font-size: 32px; /* Slightly larger heading size for prominence. */
    /* font-family: 'Lucida Calligraphy'; */
    font-weight: bold; /* Bold styling for emphasis. */
    text-align: center; /* Centers the heading text. */
  }

  .massage-description {
    font-size: 0.8rem; /* Adjusted description size. */
  }
}








@media (max-width: 600px) {
  .massage-card-grid {
    display: grid; /* Activates grid layout. */
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns. */
    max-width: 88%; /* Fills the full width of the container. */
    gap: 10px; /* Adds space between grid items. */
    /* border:2px solid goldenrod; */
  }

  .massage-circular-image-container {
    width: 60%; /* Sets width to 60% of the parent. */
    height: auto; /* Maintains aspect ratio. */
    margin: 3px auto; /* Centers the image container. */
    padding: 1px; 
  }

  .massage-card .card-title {
    font-size: 0.75rem; /* Adjusts title size for readability. */
    font-weight: bold; /* Makes the title bold. */
  }

  .massage-card .card-text {
    font-size: 0.75rem;; /* Smaller text size for compactness. */
    font-weight: normal; /* Sets normal weight for text. */
  }


  .massage-flip-card-back .massage-body {
    font-size: 0.65rem; /* or 0.75rem for 12px */
    font-weight: normal; /* Normal weight */
  }

  .massage-h1 {
    font-size: 32px; 
    /* Prominent heading size. */
    /* font-family: 'Lucida Calligraphy';  */
    font-weight: bold; /* Bold styling for emphasis. */
    text-align: center; /* Centers the heading text. */
  }
  .massage-description{
    font-size:  0.8rem;
    /* border: 2px solid black; */
  }
}






@media (max-width: 430px) {
  .massage-card-grid {
    position: relative; /* Allows absolute positioning of cards inside. */
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    /* Two equal columns. */
    max-width: 67%; /* Adjusts width to fit slightly larger screens. */
    gap: 15px; /* Space between grid items. */
    /* border:2px solid red; */
    

  }

  .massage-card {
    position: relative; /* Allows the card to be positioned relative to its normal position. */
    z-index: 1; /* Ensures the card appears above the background content. */
    transition: transform 0.3s; /* Adds a smooth transition effect. */
  }

  .massage-card:hover {
    transform: translateY(-10px); /* Moves the card up on hover for a lift effect. */
    z-index: 2; /* Brings the hovered card above others. */
  }

  .massage-circular-image-container {
    width: 60%; /* Sets width to 70% of the parent. */
    height:60%; /* Maintains aspect ratio. */
    margin: 1px auto; /* Centers the image container. */
    /* padding-top: 10px; */
  }

  .massage-card .card-title {
    font-size: 0.4rem; /* Adjusts title size for readability. */
    font-weight: bold; /* Makes the title bold. */
   
  }

  .massage-card .card-text {
    font-size: 0.1rem; /* Text size for better readability. */
    font-weight: bold; /* Normal weight for text. */
    line-height: 0.9rem;
  }

  .massage-flip-card-back .massage-body {
    font-size: 0.50rem; /* Adjusted size for the back of the card. */
    font-weight:bold; /* Normal weight. */
    padding-bottom: 4px;
    line-height: 0.5rem;
  }

  .massage-h1 {
    font-size: 32px; /* Slightly larger heading size for prominence. */
    /* font-family: 'Lucida Calligraphy'; */
    font-weight: bold; /* Bold styling for emphasis. */
    text-align: center; /* Centers the heading text. */
  }
  .massage-description{
    font-size:  0.8rem;
    /* color: red; */
    /* border: 2px solid black; */
  }
 
}




@media (max-width: 350px) {
  .massage-card-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 95%;
    gap: 10px;
  }

  .massage-card {
    position: relative;
    z-index: 1;
    transition: transform 0.3s;
  }

  .massage-card:hover {
    transform: translateY(-10px);
    z-index: 2;
  }

  .massage-circular-image-container {
    width: 60%;
    height: auto;
    margin: 3px auto;
  }

  .massage-card .card-title {
    font-size: 0.5rem; /* Title size */
    font-weight: bold;
  }

  .massage-card .card-text {
    font-size: 0.75rem; /* Default text size */
    font-weight: bold;
  }

  .massage-card:hover .card-title {
    font-size: 0.4rem; /* Reduced title size on hover */
    font-weight: bold;
  }

  .massage-card:hover .card-text {
    font-size: 0.6rem; /* Reduced text size on hover */
  }

  .massage-flip-card-back .massage-body {
    font-size: 0.65rem;
    font-weight: normal;
    padding-bottom: 2px;
    line-height: 0.7rem;
  }

  .massage-h1 {
    font-size: 32px;
    /* font-family: 'Lucida Calligraphy'; */
    font-weight: bold;
    text-align: center;
  }

  .massage-description {
    font-size: 0.9rem;
  }
}




/* Main container styling with background image */
.massage-background {
  /* background-image: url("./flower.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 100vh;
  padding: 20px;
}






/* Main container styling with background image */
.massage-background {
  /* background-image: url("./flower.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 100vh;
  padding: 20px;
  position: relative; /* Needed for positioning child elements */
}

.massage-description {
  position: relative; /* Needed for positioning the pseudo-element */
  max-width: 850px; 
  margin: 20px auto; 
  line-height: 1.6; /* Slightly increased for better readability */
  text-align: center; 
  color: #444; /* Darker text for readability */
  background-color: transparent; /* Keep background transparent */
  padding: 20px; 
  border-radius: 10px; 
  letter-spacing: 1px; 
  font-size: 1.5em; /* Attractive font size (about 24px) */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition */
  box-shadow: none; /* Remove box shadow for a cleaner look */
  text-transform: capitalize; /* Capitalize the first letter of each word */
  /* font-family: 'Arial', sans-serif; */
   /* Change font family as needed */
  font-weight: lighter;
  font-family: 'Georgia', serif;
  font-size: 1.5em;
}



/* Hover effect */
.massage-description:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  color: #17a50a; /* Change text color to a vibrant tomato color on hover */
  /* Remove text-shadow for a clean effect */
}

/* Keyframe animation for initial appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px); /* Start further down for a more noticeable effect */
  }
  to {
    opacity: 1; /* Corrected opacity value to 1 */
    transform: translateY(0);
  }
}

/* Apply the animation */
.massage-description {
  animation: fadeIn 1s ease-out; /* Increased duration to 1 second for a slower animation */
}

/* Media Queries for Responsive Font Size */
@media (max-width: 768px) {
  .massage-description {
    font-size: 1.4em; /* Adjust font size for tablets */
  }
}

@media (max-width: 480px) {
  .massage-description {
    font-size: 1.2em; /* Adjust font size for mobile */
  }
}

