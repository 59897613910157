.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: var(--navColor107);
    background-image: url('UnauthorizedUser.jpg'); /* Add your background image */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    text-align: center;
    color: var(--navColor108);
}

.unauthorized-image {
    width: 150px; /* Set the desired width */
    height: 115px; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space below the image */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px var(--navShadowColor); /* Subtle shadow */
    overflow: hidden; /* Ensure no overflow */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.unauthorized-title {
    font-size: 4rem;
    margin: 0;
    opacity: 0; /* Start invisible */
    transform: scale(0.5); /* Start small */
    animation: fadeInScale 1s ease forwards;
    font-family: var(--firstChoiceFont); /* Add your desired font family here */
}

.unauthorized-message {
    font-size: 1.5rem;
    margin-top: 10px;
    opacity: 0; /* Start invisible */
    transform: translateY(20px); /* Start below */
    animation: slideIn 1s ease forwards 0.5s;
    font-family: var(--firstChoiceFont); /* Add your desired font family here */
}

.home-page-icon {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    width: 80px; /* Maintain the icon's width */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effects */
    box-shadow: 0 4px 8px var(--navShadowColor1); /* Subtle shadow for depth */
    border-radius: 80px; /* Set border radius for rounded corners */
}

.home-page-icon:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
    box-shadow: 0 6px 12px var(--navShadowColor2); /* Increased shadow on hover */
}

@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
