.spinner-accept, .spinner-reject, .spinner-complete {
  width: 1em;
  height: 1em;
  border: 2px solid transparent;
  border-top: 2px solid currentColor; /* Uses current text color */
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rotate-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    /* white-space: nowrap; */
  }
  
  .completing-word {
    font-weight: bold;
    /* color: #007bff;  */
    /* Adjust color as needed */
  }
  


.loadeer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
  }
  
  .spinnerr {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff; /* Bootstrap primary color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


.massager-table-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: auto;
}

.massager-responsive-table {

    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.massager-responsive-table th,
.massager-responsive-table td {
   
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--navColor41);
    font-size: 1rem; 
    font-family: var(--firstChoiceFont);
    border-style: none;

}

.massager-responsive-table th {
 
    background-color: var(--navColor98);
}

.massager-dropdown {
    padding: 10px;
    border: 1px solid var(--navColor94);
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 10px;
    font-family: var(--firstChoiceFont);

}

.massager-dropdown:hover {
    
    border-color: var(--navColor99);
}

.massager-dropdown:focus {
   
    outline: none;
    border-color: var(--navColor87);
}


/* Media Queries */
@media (max-width: 1366px) {
    .massager-responsive-table th,
    .massager-responsive-table td {
        font-size: 0.95rem; /* Slightly smaller font size */
    }
}

@media (max-width: 1280px) {
    .massager-responsive-table th,
    .massager-responsive-table td {
        font-size: 0.9rem; /* Smaller font size */
    }
}

@media (max-width: 600px) {
    .massager-responsive-table th,
    .massager-responsive-table td {
        font-size: 0.8rem; /* Even smaller font size */
        padding: 8px; /* Adjust padding for smaller screens */
    }

    .massager-dropdown {
        font-size: 0.9rem; /* Smaller font size for dropdown */
    }
}


.massager-responsive-table td:nth-child(3),
.massager-responsive-table td:nth-child(6),
.massager-responsive-tablee th:nth-child(6),
.massager-responsive-table td:nth-child(5), /* Issue column */
.massager-responsive-table td:nth-child(8), /* Therapy column */
.massager-responsive-table th:nth-child(5), 
.massager-responsive-table th:nth-child(8) {
  min-width: 200px; /* Set a minimum width to ensure 4 words fit on one line */
  max-width: 350px; /* Allow the column to expand for longer text */
  word-break: normal; /* Prevent breaking of words unless absolutely necessary */
  white-space: normal; /* Allow text wrapping */
}
