.lloading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}

.carousel-container {
  background-color: var(--navColor45);
  /* Calm gray background color */
  /* padding-top: 20px; */
  /* Optional: Add some padding if needed */
 
}

.carousel-item img {
  height: 92vh !important;
  /* Set the height you prefer */
  object-fit: fill;
  /* Ensures that the image covers the area without distortion */
}

.HPHeader,
.HPPara {
  text-shadow: 2px 2px 4px var(--navColor7);
  font-family: var(--firstChoiceFont);

}

/* Responsive text sizing */
@media (max-width: 768px) {
  .HPHeader {
    font-size: 1.8rem;
    /* Smaller font size for smaller screens */
  }

  .HPPara {
    font-size: 1rem;
    /* Smaller font size for smaller screens */
  }
}

@media (max-width: 576px) {
  .HPHeader {
    font-size: 1em !important;
    /* Further reduced font size for extra-small screens */
  }

  .HPPara {
    font-size: 0.8em !important;
    /* Further reduced font size for extra-small screens */
  }
}