.custom-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    position: fixed; /* Fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
    z-index: 1000; /* On top of other content */
  }
  
.dashhead{
    font-family: var(--firstChoiceFont);

}
.cardborder{
    border-left: 8px solid var(--navColor87) !important;
    border-bottom: 10px solid var(--navColor87) !important;
    border-radius: 20px !important;
    font-family: var(--firstChoiceFont);

}

.cardborder1{
   
    border-left: 8px solid var(--navColor88)!important;
    border-bottom: 10px solid var(--navColor88) !important;
    border-radius: 20px !important;
    font-family: var(--firstChoiceFont);

}

.cardborder2{
    border-left: 8px solid var(--navColor89)!important;
    border-bottom: 10px solid var(--navColor89) !important;
    border-radius: 20px !important;
    font-family: var(--firstChoiceFont);

}

.cardborder3{
    border-left: 8px solid var(--navColor50)!important;
    border-bottom: 10px solid var(--navColor50) !important;
    border-radius: 20px !important;
    font-family: var(--firstChoiceFont);

}

.cardborder4{
    border-left: 8px solid var(--navColor90)!important;
    border-bottom: 10px solid var(--navColor90) !important;
    border-radius: 20px !important;
    font-family: var(--firstChoiceFont);

}

.cardborder:hover, .cardborder1:hover , .cardborder2:hover, .cardborder3:hover, .cardborder4:hover{
   transform: scale(1.05);
}
