.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust based on your layout */
}

/* General Container Styles */
.About_us_page_container {
  font-family: Arial, sans-serif;
  text-align: center;
  /* padding: 20px; */
  box-sizing: border-box;
  width: 100%;
}

.About_us_Video {
  background-color: var(--navColor11);
  padding: 13px 0 3px 0;
  margin: 8px auto;
}
/* Header Section */
.About_us_page_header {
  background-image: url("./alan-caishan-cU53ZFBr3lk-unsplash_11zon.jpg");
  /* Replace with correct image path */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px 20px;
  color: var(--navColor6);
  min-height: 100vh;
  width: 100%;
}

.About_us_page_title {
  font-size: 3rem;
  margin-top: 3em;
  margin-bottom: 1em;
  display: inline-block;
  /* padding-top: 12%; */
  font-weight: bolder;
  color: var(--navColor31);
  text-transform: uppercase;
  text-shadow: 2px 2px 2px var(--navColor7);
  font-family: var(--firstChoiceFont);
  transform: translateY(20px);
  animation: fadeIn 2s forwards;
}

.About_us_page_title::after {
  content: "";
  position: absolute;
  bottom: -5px;
  /* Slightly below the text */
  left: 0;
  width: 0;
  height: 4px;
  /* Thickness of the underline */
  background-color: var(--navColor30);
  /* Green shade for underline */
  transition: width 0.5s ease-out;
  /* Smooth transition effect */
}

.About_us_page_title:hover::after {
  width: 100%;
  /* Expands underline to match heading width */
}

.About_us_page_description {
  /* font-size: 1.2rem; */
  text-transform: uppercase;
  font-weight: bolder !important;
  color: var(--navColor6);
  text-shadow: 2px 2px 2px var(--navColor7);
  padding-top: 10px;
  font-family: var(--firstChoiceFont);
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

.About_us_page_header h5:hover {
  transform: scale(1.1) !important;
}

.About_us_page_header h5 {
  font-size: 1.3rem;
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  font-weight: bold;
  margin-right: auto;
  line-height: 1.5;
  color: var(--navColor6);
  text-shadow: 1px 1px 1px var(--navLinearGradient);
  /* font-family: 'Georgia', serif; */
  font-family: var(--firstChoiceFont);
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

/* Content Section: Flexbox Layout */
.About_Us_page_content {
  border: 2px solid var(--navColor7);
}

.About_us_page_Discripstion p {
  padding-top: 20px;
  columns: 2;
  /* font-family: 'Georgia', serif; */
  /* font-family: var(--firstChoiceFont) !important; */
  font-weight: bolder !important;
  /* text-align: left; */
  text-align: justify;
  text-transform: capitalize;
  padding-left: 25px;
  /* border:  2px solid red; */
}

/* .About_us_page_left,
.About_us_page_right {
  flex: 1;
  padding: 20px;
  min-width: 300px;
   
  width: 100px;
} */

/* .About_us_page_left, .About_us_page_right {
  text-align: center;
} */

/* .About_us_page_right_child {
  background-color: #529ff2;
  width: 70%;
  height: 100%;
  padding: 10%;
} */

/* .About_us_page_left_child {
  width: 70%;
  margin-left: 30%;
} */

.About_us_page_subtitle {
  font-size: 2rem;
  color: var(--navColor61);
  text-transform: uppercase;
  font-family: var(--firstChoiceFont);
}

/* .About_us_page_map {
  width: 100%;
  max-width: 100%; 

  height: 300px;
  border: none;
} */

/* .About_us_page_address,
.About_us_page_phone,
.About_us_page_Email,
.About_us_page_YouTub {
  text-shadow: 2px 2px 2px var(--navColor7);
  font-size: 1.2rem;
  color: var(--navColor6);
  margin-top: 10px;
} */

/* .About_us_page_phone a {
  color: #e0e3e7;
  text-decoration: none;
}

.About_us_page_Email a {
  color: #e0e3e7;
  text-decoration: none;
}

.About_us_page_YouTub a {
  color: #e0e3e7;
  text-decoration: none;
}

.About_us_page_phone a:hover {
  text-decoration: underline;
}

.About_us_page_map:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

/* Container */
/* Container */
.About_Us_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* Ensures it wraps on smaller screens */
  /* padding: 40px 0; */
  gap: 20px;
  opacity: 0;
  /* Initially hidden */
  animation: fadeIn 1s ease forwards;
  /* Fade-in animation */
}

/* Left side: Paragraph */
.About_us_ParaDiscription {
  flex: 1;
  /* padding-left: 4em; */
  padding: 2em;
  max-width: 50%;
  border: 10px solid gray;
  /* Take 50% of the width */
  /* opacity: 0; 
  transform: translateX(-100%); 
  animation: slideInLeft 3s ease forwards;
  animation-delay: 0.3s; 
  animation-timeline: scroll();
  animation-range: entry 1;
  font-weight: bold;
  font-family: var(--firstChoiceFont); */
}

.About_us_ParaDiscription p:hover {
  /* background-color: #e0f7fa;  */
  transform: scale(1.07);
  /* Slightly increase the size on hover */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  */
}

/* Right side: Image */
.About_Us_img_div {
  padding: 2em 2em 2em 0em;
  /* top, right, bottom, left */
  flex: 1;
  max-width: 50%;
  /* Take 50% of the width */

  /* opacity: 0; 
  transform: translateX(100%); 
  animation: slideInRight 3s ease forwards;
  animation-delay: 0.3s; 
  animation-timeline: scroll();
  animation-range: entry 1; */
}

.IMG_About_sec_dev {
  height: 380px;
  width: 600px;
  /* border: 10px solid grey; */
  padding: 5px;
}

.Img_About_us_discription:hover {
  transform: scale(1.05);
}

.About_us_ParaDiscription p {
  text-align: justify;
  font-weight: bolder !important;
  /* text-indent: 15%; */
  justify-content: baseline;
}

/* Keyframe Animations */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Media Query for screens smaller than 750px */
@media (max-width: 770px) {
  .About_us_ParaDiscription,
  .About_Us_img_div {
    max-width: 100%;
    /* Make each div take full width */
    margin-bottom: 20px;
  }

  .About_us_ParaDiscription p {
    padding-right: 10%;
    text-align: justify;
  }

  .About_Us_img_div {
    padding: 0rem 0rem 0rem 0rem;
    height: 400px;
    width: 700px;
    align-items: center;
  }

  .About_Us_content {
    display: block;
    width: 100%;
    /* border: 2px solid var(--navColor7); */
  }

  .Img_About_us_discription {
    height: 400px;

    justify-content: center;
    align-items: center;
    /* margin-left: 100px; */
  }

  .IMG_About_sec_dev {
    align-items: center;
    height: 400px;
    width: 680px;
    padding: 0px;
    margin: 0.5em;
  }
}

@media (max-width: 1030px) {
  .IMG_About_sec_dev {
    height: 400px;
    width: 500px;
  }

  .About_us_ParaDiscription p {
    margin-left: 20px;
  }
}

/* Media Query for screens smaller than 420px (row format) */
@media (max-width: 440px) {
  .About_Us_content {
    /* padding: 20px 0; */
    flex-direction: row;
    /* Ensures row format */
    justify-content: center;
    /* Centers content */
  }

  .About_us_ParaDiscription {
    /* max-width: 45%;  */
    margin-bottom: 0;
    padding-left: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: none;
    /* Disable animations */
    transform: none;
    /* Remove transform effects */
    opacity: 1;
  }

  .About_Us_img_div {
    display: flex;
    flex-direction: column;
    animation: none;
    /* Disable animations */
    transform: none;
    /* Remove transform effects */
    opacity: 1;
  }

  .About_us_ParaDiscription {
    order: 1;
    /* Ensure description appears first */
  }

  .About_Us_img_div {
    order: 2;
    /* Ensure image appears second */
  }

  .About_us_page_header h5 {
    font-size: 1em;
    padding-top: 20px;
    margin: 10px 2%;
    text-align: justify;
    text-align: center;
  }
}

/* Responsive Adjustments */

/* For large desktops */
@media (max-width: 1200px) {
  .About_us_page_title {
    font-size: 2.5rem;
  }
}

@media (max-width: 440px) {
  /* .About_us_page_Discripstion {
   columns: 2;
   text-indent:  20PX;
  text-align: justify;
  padding-right: 8PX;
  font-size: small;

 
  
 } */
  .About_us_page_title {
    margin-top: 170px;
    font-size: 2rem;
  }

  /* .About_us_page_description{
  font-weight:bold;
  width: 100%;
  border: 2px solid var(--navColor7);
   padding-left: -20px;
   margin-left: 0px;
   padding:  0px 0px 0px 0px;
} */
  .About_us_ParaDiscription {
    padding-left: 0px;
  }

  .About_us_ParaDiscription p {
    margin-left: 0px;
    text-align: justify;
    text-indent: 10%;
    padding: 2% 0% 2% 6%;
    margin: 0 2px;
  }

  .About_Us_content {
    display: block;
    width: 100%;
  }

  .About_Us_img_div {
    padding: 0rem 0rem 0rem 0rem;
  }

  .IMG_About_sec_dev {
    width: 85%;
    height: 100%;
  }

  .Img_About_us_discription {
    height: 300px;
  }

  .About_Us_img_div {
    height: 300px;
  }
}
