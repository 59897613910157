/* Ensure the body and html take full height */
html, body {
  height: 100%;
  margin: 0;
}

/* Center the loading spinner */
.loading-spinner40 {
  position: fixed; /* Use fixed positioning */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000; /* Ensure it appears above other elements */
  display: flex; /* Use flexbox to center the spinner */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Spinner styles remain the same */
.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px; /* Spinner size */
  height: 50px; /* Spinner size */
  animation: spin 1s linear infinite; /* Rotate animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Report_Admin_Page_container {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  
}

.Report_Admin_Page_heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: var(--firstChoiceFont);

}

/* Flexbox row layout for date selectors and buttons */
.Report_Admin_Page_dateSelectors {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between elements */
  gap: 10px;
  margin-bottom: 20px;
}

.Report_Admin_Page_input {
  padding: 8px;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);

}

.Report_Admin_Page_buttonContainer {
  margin-left: 10px;
}

.Report_Admin_Page_button {
  padding: 8px 15px;
  background-color: var(--navColor59);
  color: var(--navColor6);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);

}

.Report_Admin_Page_button:hover {
  background-color: var(--navColor95);
}

/* Download GIF styling */
.Report_Admin_Page_gifContainer {
  margin-left: auto; /* Align GIF to the right */
}

.Report_Admin_Page_downloadGif {
  cursor: pointer;
  width: 50px; /* Adjust size as per requirement */
  height: auto;
}

/* Table styles */
.Report_Admin_Page_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.Report_Admin_Page_table th, 
.Report_Admin_Page_table td {
  border-bottom: 1px solid var(--navColor41);
  padding: 8px;
  text-align: left;
  font-family: var(--firstChoiceFont);

}

.Report_Admin_Page_table th {
  background-color: var(--navColor96);
}

/* Responsive Design */
@media (max-width: 768px) {
  .Report_Admin_Page_dateSelectors {
    flex-wrap: wrap;
    gap: 5px;
  }

  .Report_Admin_Page_input,
  .Report_Admin_Page_button
  {
    width: 100%;
    margin: 5px 0;
  }

  .Report_Admin_Page_downloadGif{
    margin: 5px 0;

  }
  .Report_Admin_Page_gifContainer {
    text-align: center; /* Center the GIF on small screens */
  }
}

.Report_Admin_Page_table th:nth-child(6),
.Report_Admin_Page_table td:nth-child(6),
.Report_Admin_Page_table th:nth-child(7),
.Report_Admin_Page_table td:nth-child(7){
  min-width: 150px; /* Set a minimum width to ensure 4 words fit on one line */
  max-width: 350px; /* Allow the column to expand for longer text */
  word-break: normal; /* Prevent breaking of words unless absolutely necessary */
  white-space: normal; 
}