/* Default content margin for larger screens */
/* .content.apply-margin {
  margin-top: calc(5vh + 20px);
} */

/* No margin when admin or massager panel is active */
/* .content {
  margin-top: 0;
} */

/* Responsive styles for medium screens */
/* @media (max-width: 951px) {
  .content.apply-margin {
    margin-top: calc(4vh + 10px);
  }
} */

/* Responsive styles for small screens */
/* @media (max-width: 500px) {
  .content.apply-margin {
    margin-top: calc(2vh + 8px);
  }
} */

/* Responsive styles for extra small screens */
/* @media (max-width: 360px) {
  .content.apply-margin {
    margin-top: calc(2vh + 5px);
  }
} */

/* Remove scrollbar */
body::-webkit-scrollbar {
  display: none;
}

/* Ensure smooth scroll for sections */
.section {
  scroll-margin-top: 80px; /* Adjust this value as needed */
  scroll-snap-margin-top: 80px;
}

:root {
  /********** Color Variabel*********** */

  --navColor1: #b0ef91;
  --navColor2: #bcf99d;
  --navColor3: #c4a5a5;
  --navColor4: #82b436;
  --navColor5: #0a8e2e;
  --navColor6: #ffffff;
  --navColor7: #000000;
  --navColor8: #b4f6b4;
  --navColor9: #b2fbb2;
  --navColor10: #e8b12f;
  --navColor11: #f5deb3;
  --navColor12: #ff69b4;
  --navColor13: #a52a2a;
  --navColor14: #85da5e;
  --navColor15: #ff0000;
  --navColor16: #00c9ff;
  --navColor17: #92fe9d;
  --navColor18: #00b4db;
  --navColor19: #0083b0;
  --navColor20: #ff7e5f;
  --navColor21: #feb47b;
  --navColor22: #f7ab1c;
  --navColor23: #f87c3a;
  --navColor24: #4e54c8;
  --navColor25: #8f94fb;
  --navColor26: #1d976c;
  --navColor27: #93f9b9;
  --navColor28: #19d694;
  --navColor29: #6ce112;
  --navColor30: #6a1b9a;
  --navColor31: #ab47bc;
  --navColor32: #e40675;
  --navColor33: #ff4500;
  --navColor34: #fe0077;
  --navColor35: #268c2a;
  --navColor36: #082d09;
  --navColor37: #f5f5f5;
  --navColor38: #2f00ff;
  --navColor39: #dc143cdc;
  --navColor40: #ff6347;
  --navColor41: #dddddd;
  --navColor42: #4169e1;
  --navColor43: #fffaf0;
  --navColor44: #634a4a;
  --navColor45: #f0f0f0;
  --navColor46: #e6e6e6;
  --navColor47: #f8f9fa;
  --navColor48: #dee2e6;
  --navColor49: #343a40;
  --navColor50: #dc3545;
  --navColor51: #c82333;
  --navColor52: #ced4da;
  --navColor53: #495057;
  --navColor54: #80bdff;
  --navColor55: #007bff40;
  --navColor56: #007bff;
  --navColor57: #0056b3;
  --navColor58: #6a7dc2;
  --navColor59: #4caf50;
  --navColor60: #5e575728;
  --navColor61: #080404;
  --navColor62: #dfffdc;
  --navColor63: #e6e6fa;
  --navColor64: #083f18;
  --navColor65: #90ee90;
  --navColor66: #5dcc70;
  --navColor67: #1b5e20;
  --navColor68: #8bc34a;
  --navColor69: #333333;
  --navColor70: #74aeed;
  --navColor71: #080808;
  --navColor72: #e0e3e7;
  --navColor73: #021212;
  --navColor74: #0000ff;
  --navColor75: #ffc0cb;
  --navColor76: #08ec6b;
  --navColor77: #3192f3;
  --navColor78: #dde9ea;
  --navColor79: #dfcdcd37;
  --navColor80: #0cde67;
  --navColor81: #d13066;
  --navColor82: #21214c;
  --navColor83: #ffffff99;
  --navColor84: #00000059;
  --navColor85: #00000014;
  --navColor86: #8808d359;
  --navColor87: #529ff2;
  --navColor88: #198754;
  --navColor89: #ffc107;
  --navColor90: #0dcaf0;
  --navColor91: #f7f7f7;
  --navColor92: #28a745;
  --navColor93: #218838;
  --navColor94: #cccccc;
  --navColor95: #45a049;
  --navColor96: #f4f4f4;
  --navColor97: #e7ecf1;
  --navColor98: #f2f2f2;
  --navColor99: #888888;
  --navColor100: #f00bab59;
  --navColor101: #5bdee7;
  --navColor102: #7351b3;
  --navColor103: #13b439;
  --navColor104: #d8a12b;
  --navColor105: #6c757d;
  --navColor106: #a6a6a6;
  --navColor107: #f0f4f8;
  --navColor108: #333;
  --navColor109: #FFFFFF;
  --navColor1010: #007bff;
  --navColor1011: #df7eca;
  

  /* --navColor107 : #888888;
  --navColor108 : #888888;
  --navColor109 : #888888;
  --navColor110 : #888888; */

  --navlinkColor: #004d00;

  --navShadowColor: #0000001a;
  --navShadowColor1: #00000033;
  --navShadowColor2: #0000004d;
  --navShadowColor3: #ffffff4d;
  --navShadowColor4: #00000099;
  --navShadowColor5: #007BFF4C;
  --navShadowColor6: #A8058566;
  --navShadowColor7: #0278F74C;
  /* Color5 and Color1 */
  --navShadowColor5: #00000033;
  --navShadowColor6: #e0e0e0be;
  --navShadowColor7: #00000080;
  --navShadowColor8: #63a2a27c;
  --navShadowColor9: #00000026;
  --navShadowColor10: #56f7564d;
  --navShadowColor11: #ab979780;
  /* --navShadowColor1 : #56f7564d; */

  --navLinearGradient: #0eb03e;
  --navLinearGradient1: #2998bd;
  --navLinearGradient2: #1d7a9e;
  --navLinearGradient3: #1d8a4e;
  --navLinearGradient4: #155e3b;
  --navLinearGradient5: #ff634700;
  /* --navLinearGradient6 : #; */


 

  /********** Font Variabel*********** */

  --firstChoiceFont: 'Vedanta', 'Merriweather', Georgia, 'Times New Roman', serif;
}
