
.customer-model-content{
    background-color: var(--navColor46) !important;
    /* background:url(./Images/CustomerLoginBG.gif); */
    background:url(./Images/CustomerLoginBG2.png);
    background:url(./Images/bglogin.png);
    /* padding: 1em; */
    border-radius: 15px !important;
    margin-top: 5em !important;
    overflow: hidden; /* Prevent overflow */

}
.customer-model-header {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding: 10px;
  background-color: var(--navColor47); 
  border-bottom: 1px solid var(--navColor48); 
  /* border-radius: 15px !important; */
  border-radius: 15px 15px 0 0 !important;

  font-family: var(--firstChoiceFont);


  position: sticky;
  top: 0; 
  z-index: 10; 
}

.customer-modal-body {
  max-height: 470px; 
  overflow-y: auto; 
  padding: 1em; 
  position: relative; 
}

.customer-modal-title {
  font-size: 1.5em; 
  font-weight: bold; 
  color: var(--navColor49); 
  text-align: center; 
  flex-grow: 1;
  font-family: var(--firstChoiceFont);

}

.customer-close {
  background: transparent; 
  border: none; 
  font-size: 1.5rem; 
  color: var(--navColor50); 
  cursor: pointer; 
  transition: color 0.2s; 
}

.customer-close:hover {
  color: var(--navColor51);
}

.customer-close:focus {
  outline: none; 
}

.customer-form-group {
  margin-bottom: 0.3em; 
}

.customer-form_label {
  display: block; 
  font-size: 1em; 
  margin-bottom: 0.3em; 
  color: var(--navColor7); 
  font-weight: bold;
  font-family: var(--firstChoiceFont);

}

.customer-form-control {
  width: 100%; 
  padding: 0.5em 0.75em; 
  border: 0.1em solid var(--navColor52); 
  border-radius: 0.25em; 
  font-size: 1em; 
  color: var(--navColor53); 
  transition: border-color 0.2s, box-shadow 0.2s; 
  font-family: var(--firstChoiceFont);

}

.customer-form-control:focus {
  border-color: var(--navColor54); 
  outline: none; 
  box-shadow: 0 0 0 0.2em var(--navColor55); 
}

.customer-error {
  color: var(--navColor50); 
  font-size: 0.875em; 
  margin-top: 0.25em; 
  font-family: var(--firstChoiceFont);

}

.select-container{
  font-family: var(--firstChoiceFont);

}



.customer-button-container {
  display: flex; 
  justify-content: center; 
  margin-top: 1em; 
}

.customer-btn {
  padding: 0.75em 1.5em; 
  font-size: 1em; 
  color: var(--navColor6); 
  background-color: var(--navColor56); 
  border: none; 
  border-radius: 0.6em; 
  cursor: pointer; 
  transition: background-color 0.2s; 
  width: 50%;
  font-family: var(--firstChoiceFont);

}

.customer-btn:hover {
  background-color: var(--navColor57); 
}

.customer-btn:focus {
  outline: none; 
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .customer-modal-title {
    font-size: 1.25em; 
  }
  
  .customer-form-control {
    font-size: 0.87em; 
  }

  .customer-btn {
    width: 100%;
  }
}

/* Media query for screens between 769px and 1388px */
@media (min-width: 769px) and (max-width: 1388px) {
  .customer-modal-title {
    font-size: 1.5em; 
    color: var(--navColor12) !important
  }

  .customer-form-control {
    /* font-size: 1em;  */
    /* font-size: 0.5em; */
    font-size: 0.9em !important;
    padding: 0.25em 0.5em !important; 
    height: auto;
  }

  .customer-btn {
    width: 50%;
  }

  .customer-model-header{
    padding: 10px !important;
  
  }
  .customer-form-group {
    margin-bottom: 0.1em; 
  }

  .customer-form_label{
    margin-bottom: 0.1em;
    /* font-weight: normal; */
  }

}

/* Media query for screens smaller than 600px */
@media only screen and (max-width: 600px) {

  .customer-modal-title {
    color: var(--navColor15);
  }
  .customer-form-group {
    margin-bottom: 1em; 
  }

  .customer-btn {
    padding: 0.5em; 
    font-size: 0.9em; 
    width: 50%;

  }
}
