.loadding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}

/* Background image for the entire page */
.page-background {
  /* background: url('./Images/1251697.jpg') no-repeat center center fixed; */
  background: url("./Images/1251812.jpg") no-repeat bottom center fixed;
  /* background: url('./Images/1251892.jpg') no-repeat center center fixed; */

  background-size: cover;
  /* filter: blur(5px); */
  min-height: 100vh;
  margin: 0;
}

/* Container for the entire navigation */
.tre-nav-wrap {
  margin: 0 0 2px 0;
  padding: 20px 0;
  /* z-index: 2; */
}

/* Header style */
.nav-treatment-header {
  margin: 1em 0 0.5em 0;
  /* font-family: "Indie Flower"; */
  font-family: var(--firstChoiceFont);
  font-weight: bolder;
  font-size: 3em;
  margin-bottom: 2.5em;

  color: var(--navColor10);
  /* color:var(--navColor11); */
  /* color: var(--navColor12); */
  /* For Image three */
  /* color: var(--navColor13); */
  display: inline-block;
  text-shadow: 2px 2px 4px var(--navShadowColor4);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s forwards;
}

.nav-treatment-header::after {
  content: '';
  position: absolute;
  bottom: -5px; /* Slightly below the text */
  left: 0;
  width: 0;
  height: 4px; /* Thickness of the underline */
  background-color: var(--navColor10) ; /* Green shade for underline */
  transition: width 0.5s ease-out; /* Smooth transition effect */
}

.nav-treatment-header:hover::after {
  width: 100%;
}
/* Info paragraph style */
.nav-treatment-info {
  font-size: 2em;
  color: var(--navColor6) !important;
  /* color: var(--navColor6); */
  /* color: var(--navColor7); */
  text-shadow: 2px 2px 4px var(--navShadowColor4); /* Example shadow */
  font-family: var(--firstChoiceFont);


  margin-bottom: 10em;

  /* For Image three */
  /* color: var(--navColor7);  */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s 0.5s forwards;
}

/* Card container style */
.treatment-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  /* margin-top; */
}

/* Individual card style */
.nav-treatment-card {
  flex: 1 1 170px; /* Allows the cards to grow and shrink with a minimum width of 300px */
  min-height: 190px; /* Set a minimum height */

  /* border: 1px solid var(--navColor14); */
  border: 1px solid var(--navColor7);
  /* border: 1px solid var(--navColor15); */

  border-radius: 10px;
  box-shadow: 0 4px 8px var(--navShadowColor5);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease,background-color 0.3s ease;
  background-color: var(--navShadowColor6) !important;
}

.nav-treatment-card:hover {
  transform: scale(1.1) !important;

  /* For Image one and three */
  /* background: linear-gradient(135deg,var(--navColor16), var(--navColor17));  */

  /* For Image one and three */
  /* background: linear-gradient(135deg, var(--navColor18), var(--navColor19)); */

  /* For Image Two  and three */
  /* background: linear-gradient(135deg, var(--navColor20), var(--navColor21)); */

  /* For Image Two and three */
  background: linear-gradient(135deg, var(--navColor22), var(--navColor23));

  /* For Image Three */
  /* background: linear-gradient(135deg, var(--navColor24), var(--navColor25)); */

  /* For Image Three */
  /* background: linear-gradient(135deg, var(--navColor26), var(--navColor27)); */

  /* For Image Three */
  /* background: linear-gradient(135deg, var(--navColor28), var(--navColor29)); */

  /* background: linear-gradient(135deg, var(--navColor30), var(--navColor31)); */

  /* box-shadow: 0 8px 16px var(--navShadowColor2); */
  box-shadow: 0 8px 16px var(--navColor12);
}

/* Card title style */
.nav-treatment-card .nav-tret-title {
  font-size: 1.5em;
  font-weight: bolder;
  text-align: center;
  font-family: var(--firstChoiceFont);


  /* color: var(--navColor35); */
  /* color:var(--navColor34); */
  color: var(--navColor33);
  text-shadow: 1px 1px 3px var(--navShadowColor7);
}

.nav-treatment-card:hover .nav-tret-title {
  color: var(--navColor36);
}

/* Card text style */
.nav-treatment-card .nav-tret-text {
  font-size: 1.2em;
  text-align: justify;
  color: var(--navColor7);
  text-shadow: 1px 1px 3px var(--navShadowColor7);
  font-family: var(--firstChoiceFont);

}

.nav-treatment-card:hover .nav-tret-text {
  color: var(--navColor37);
}

/* Link inside card */
.card-link {
  text-transform: uppercase;
  color: var(--navColor38);
  font-size: 0.7em;
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
  font-family: var(--firstChoiceFont);

  /* padding: 0.5em 1em; */
}

.card-link:hover {
  color: var(--navColor15);
  text-decoration: underline;
}

/* Media Queries  */
/* ************************************************************************** */

/* Responsive styles */
@media (max-width: 768px) {
  .nav-treatment-header {
    font-size: 1.4em;
  }

  .nav-treatment-info {
    font-size: 1em;
  }

  .nav-treatment-card {
    font-size: 0.9em;
    text-align: justify;
  }

  .treatment-cards-container {
    /* Ensure 2 cards per row on smaller screens */
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Adjust gap as needed */
    /* text-align: justify; */
  
  }

  .nav-treatment-card {
    min-height: 250px; /* Remove fixed height for better adaptivity */
  }
}

@media (max-width: 1388px) and (min-width: 769px) {
  .nav-treatment-header {
    /* font-size: 1.4em; */
    color: var(--navColor12);
    margin-bottom: 1em;
    margin-top: 2.2em;
  }

  .nav-treatment-info {
    font-size: 2em;
    margin-bottom: 8em;

  }
  
  .nav-treatment-card .nav-tret-title {
    font-size: 1.5em;
  }

  .nav-treatment-card .nav-tret-text {
    font-size: 1em;
  }
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Your styles for small devices */

  .treatment-cards-container {
    /* For very small screens, ensure single column layout */
    grid-template-columns: 1fr;
    gap: 10px; /* Adjust gap as needed */
  }


  .nav-treatment-header {
    font-size: 1.6em;
    margin-bottom: 1em;
    
  }

  .nav-treatment-info {
    font-size: 0.8em;
    text-align: justify;
    padding: 5px 0 0 0;
    margin-bottom: 1em;
  }

  .nav-treatment-card{
    min-height: 190px;
  }
  .nav-treatment-card:hover {
    transform: scale(1.05) !important;
  }

  .nav-treatment-card .nav-tret-title {
    font-size: 1.2em;
  }

  .nav-treatment-card .nav-tret-text {
    font-size: 1.2em;
  }

  .tre-nav-wrap {
    padding: 30px;
  }

  .page-background {
    background: url("./Images/mobile_bg.avif") no-repeat center center fixed;

    background-size: cover;
    /* filter: blur(5px); */
    min-height: 100vh;
    /* margin: 0; */
  }
}



/* Media Queries for 425px */
@media only screen and (max-width: 425px) {
  .nav-treatment-header {
    font-size: 1.4em; /* Slightly smaller font for the header */
    margin-bottom: 0.8em; /* Adjusted margin */
  }

  .nav-treatment-info {
    font-size: 0.9em; /* Smaller font for info */
    margin-bottom: 2em; /* Adjusted margin */
    padding: 5px 0; /* Maintain padding */
  }

  .treatment-cards-container {
    /* Ensure single column layout for very small screens */
    display: grid; /* Use grid for better control */
    grid-template-columns: 1fr; /* One column */
    gap: 10px; /* Space between cards */
  }

  .nav-treatment-card {
    min-height: 190px; /* Adjust height for cards */
  }

  .nav-treatment-card .nav-tret-title {
    font-size: 1.1em; /* Smaller title font */
  }

  .nav-treatment-card .nav-tret-text {
    font-size: 0.8em; /* Smaller text font */
  }

  .card-link {
    font-size: 0.6em; /* Smaller link font */
  }

  .tre-nav-wrap {
    padding: 15px; /* Less padding for smaller screens */
  }

  .page-background {
    background: url("./Images/mobile_bg.avif") no-repeat center center fixed;
    background-size: cover; /* Cover background */
    min-height: 100vh; /* Maintain full height */
  }
}


/* Media Queries for 375px */
@media only screen and (max-width: 376px) {
  .nav-treatment-header {
    font-size: 1.3em; /* Smaller font for header */
    margin-bottom: 0.5em; /* Adjusted margin */
  }

  .nav-treatment-info {
    font-size: 0.8em; /* Smaller font for info */
    margin-bottom: 1.5em; /* Adjusted margin */
    padding: 5px 0; /* Maintain padding */
  }

  .treatment-cards-container {
    display: grid; /* Use grid for better control */
    grid-template-columns: 1fr; /* One column */
    gap: 5px; /* Space between cards */
  }

  .nav-treatment-card {
    min-height: 170px; /* Adjust height for cards */
  }

  .nav-treatment-card .nav-tret-title {
    font-size: 1.2em; /* Smaller title font */
  }

  .nav-treatment-card .nav-tret-text {
    font-size: 1.1em !important; /* Smaller text font */
  }

  .card-link {
    font-size: 0.7em; /* Smaller link font */
  }

  .tre-nav-wrap {
    padding: 10px; /* Less padding for smaller screens */
  }

  .page-background {
    background: url("./Images/mobile_bg.avif") no-repeat center center fixed;
    background-size: cover; /* Cover background */
    min-height: 100vh; /* Maintain full height */
  }
}

/* ************************************************************************** */

/* Animationa When loads the Page */
/* ************************************************************************** */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-animate {
  animation: fadeInUp 0.8s ease-out forwards;
}

.card-animate:nth-child(1) {
  animation-delay: 0.2s;
}

.card-animate:nth-child(2) {
  animation-delay: 0.4s;
}

.card-animate:nth-child(3) {
  animation-delay: 0.6s;
}

.card-animate:nth-child(4) {
  animation-delay: 0.8s;
}

.card-animate:nth-child(5) {
  animation-delay: 1s;
}

/* ************************************************************************** */
