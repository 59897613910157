.loading-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}
.neuro-treatments-container {
    padding: 0;
    background-color: var(--navColor39);
    background-image: url('./Images/AdvanceBg1.jpg'); /* Add your background image */
    padding-top: 20px;
  }
  
  .neuro-treatments-heading {
    text-align: center;
    color: var(--navColor40); 
    margin: 0.5em 0 0.5em 0.8em;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 3px 3px 6px (--navColor7);
    padding: 0.4em;
    position: relative;
    display: inline-block;
    font-family: var(--firstChoiceFont);

  }
  
  .neuro-treatments-heading::after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, var(--navLinearGradient5) 0%, var(--navColor40) 50%, var(--navLinearGradient5) 100%);
    position: absolute;
    bottom: -10px;
    left: 0;
    background-size: 200% 100%;
    animation: wave 2s infinite;
  }
  
  @keyframes wave {
    0% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  
  .neuro-treatments-list {
    margin: 0 auto;
    padding: 0.8em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the items horizontally */
  }
  
  .neuro-treatment-fullscreen {
    position: relative;
    height: 70vh;
    overflow: hidden;
    border: 1px solid var(--navColor41); 
    background-color: var(--navShadowColor8);
    margin-bottom: 2em;
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjust vertical alignment within each card */
    align-items: center; /* Center the content within the card */
  }
  
  .neuro-treatment-fullscreen:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 50px var(--navColor12); 
  }
  
  .neuro-treatment-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
  
  .neuro-treatment-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .neuro-treatment-message {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: var(--navShadowColor7); 
    color: var(--navColor6);
    z-index: 2;
    width: 100%;
    text-align: center;
    height: 12em;
  }
  
  .neuro-icon-container {
    font-size: 2rem;
    margin-bottom: 5px;
    color: var(--navColor42);
    color: var(--navColor6);
    color: var(--navColor43);
  }
  
  .neuro-treatment-message h3 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--navColor40);
    text-shadow: 1px 1px 2px var(--navColor6); 
    font-weight: bolder;
    font-family: var(--firstChoiceFont);

  
  }
  
  .neuro-treatment-message p {
    margin: 0;
    font-size: 1rem;
    font-family: var(--firstChoiceFont);

  }
  


 /********************************************************************************/
 
  /* Media queries */
  @media (max-width: 1366px) {
    .neuro-treatments-heading {
      font-size: 1.8rem;
      margin: 0.5em 0;
    }
  
    .neuro-treatment-fullscreen {
      height: 60vh;
    }
  
    .neuro-treatment-message {
      padding: 15px;
    }
  
    .neuro-icon-container {
      font-size: 1.5rem;
    }
  
    .neuro-treatment-message h3 {
      font-size: 1.3rem;
    }
  
    .neuro-treatment-message p {
      font-size: 0.9rem;
    }
  }

  
/* Media query for screens with a width of 1280px or less */

  @media (max-width: 1280px) {
    .neuro-treatments-heading {
      font-size: 1.7rem;
      margin: 0.5em 0;
    }
  
    .neuro-treatment-fullscreen {
      height: 55vh;
    }
  
    .neuro-treatment-message {
      padding: 15px;
    }
  
    .neuro-icon-container {
      font-size: 1.4rem;
    }
  
    .neuro-treatment-message h3 {
      font-size: 1.2rem;
    }
  
    .neuro-treatment-message p {
      font-size: 0.9rem;
    }
  }


/* Media query for screens with a width of 600px or less */
  
  @media (max-width: 600px) {
    .neuro-treatments-heading {
      font-size: 1.5em;
      margin: 1em 0;
    }
  
    .neuro-treatment-fullscreen {
      height: 34vh;
      margin-bottom: 1em;
    }
  
    .neuro-treatment-message {
      padding: 8px;
    }
  
    .neuro-icon-container {
      font-size: 1em;
    }
  
    .neuro-treatment-message h3 {
      font-size: 1em;
    }
  
    .neuro-treatment-message p {
      font-size: 0.7em;
    }
  
    .neuro-treatment-image img {
      height: auto;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  