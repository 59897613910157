
/* Flexbox layout for sidebar and content */
.admin-panel {
  display: flex;
  height: 100vh; /* Full viewport height */
}

/* Sidebar should remain a fixed width */
.sidebar {
  /* flex: 0 0 220px;  */
  position: relative;
  z-index: 100;
}

/* Content section takes up the remaining space */
.admin-content {
  flex: 1;
  padding: 20px; /* Add some padding for the main content */
  background-color: var(--navColor37); /* Light background for contrast */
  /* overflow-y: auto; Allow scrolling if the content overflows */
  overflow-x: auto;
}

/* Main content adjustments */
main {
  padding: 5px;
  background: var(--navColor6);
  border: 10px solid var(--navColor86);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--navShadowColor7);
  min-height: 100%;
  overflow-x: auto;
}
