/* ConfirmBox.css */
.confirm-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.confirm-box {
  background: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.confirm-box-header {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.confirm-box-message {
  font-size: 1rem;
  margin-bottom: 20px;
}

.confirm-box-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.confirm-box-button {
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-box-button-yes {
  background-color: #007bff;
  color: white;
  border: none;
}

.confirm-box-button-no {
  background-color: #dc3545;
  color: white;
  border: none;
}

.confirm-box-button:hover {
  opacity: 0.9;
}
