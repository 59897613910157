/* Edit Customer List button Loadder */
.loader-icon {
  width: 1rem;  /* Adjust the size of the spinner */
  height: 1rem; /* Adjust the size of the spinner */
  margin-right: 0.5em; /* Space between spinner and text */
}

.btn-update {
  display: flex;         /* Use flexbox for alignment */
  align-items: center;   /* Center items vertically */
}

.text-status {
  margin-left: 0.5em; /* Space between spinner and text */
}


/* Page Loadder */
.spinner-container50 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
}

.spinner-container .spinner-border {
  width: 3rem; /* Adjust size */
  height: 3rem; /* Adjust size */
  color: #007bff; /* Change color */
}

.customer-modal-scrollable{
  padding: 0 1em 0 0; 
  max-height: 60vh; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Enable vertical scrolling */
  
}

/* Container for the table */
.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto; /* Horizontal scroll for very narrow screens */
}

.table-container h2 {
  font-family: var(--firstChoiceFont);
}

.customer-list-form_label{
  display: block; 
  font-size: 1em; 
  margin-bottom: 0.3em; 
  color: var(--navColor7); 
  font-weight: bold;
  font-family: var(--firstChoiceFont);
}

.customer-list-modal-body{
  background-color: var(--navColor27);
}

/* Table styles */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: var(--firstChoiceFont);

}

/* Table headers and cells */
.responsive-table th {
  padding: 12px;
  border-bottom: 1px solid var(--navColor41);
  background-color: var(--navColor98);
  font-family: var(--firstChoiceFont);
  font-size: 1rem;
  text-align: left;
}

.responsive-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--navColor41);
  font-family: var(--firstChoiceFont);

  /* white-space: normal;  */
  /* word-break:normal ;  */


}

/* .responsive-table td:nth-child(5){
  max-width: 100px ;
  
  word-break:normal;
} */

/* Give more space to Issue and Therapy columns */


.responsive-table td:nth-child(2),
.responsive-table th:nth-child(2),
.responsive-table td:nth-child(6),
.responsive-table th:nth-child(6),
.responsive-table th:nth-child(11),
.responsive-table td:nth-child(11),
.responsive-table th:nth-child(5),
.responsive-table td:nth-child(5){
  min-width: 150px; /* Set a minimum width to ensure 4 words fit on one line */
  max-width: 350px; /* Allow the column to expand for longer text */
  word-break: normal; /* Prevent breaking of words unless absolutely necessary */
  white-space: normal; 
}


.responsive-table td:nth-child(10), /* Issue column */
.responsive-table th:nth-child(10) 
 {
  min-width: 200px; 
  max-width: 350px; 
  word-break: normal; 
  white-space: normal; 
 }
/* Table row hover effect */
.responsive-table tr:hover {
  background-color: var(--navColor97);
}

/* Massager dropdown styles */
.massager-dropdown {
  padding: 10px;
  border: 1px solid var(--navColor94);
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--firstChoiceFont);
}

.massager-dropdown:hover {
  border-color: var(--navColor99);
}

.massager-dropdown:focus {
  outline: none;
  border-color: var(--navColor87);
}

/* Button styles */
.responsive-table .btn {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  font-family: var(--firstChoiceFont);
}

/* Media Query for Tablets (Up to 768px) */
/* @media (max-width: 768px) {
  .responsive-table th, 
  .responsive-table td {
    padding: 8px;
    font-size: 0.9rem;
  }

  .massager-dropdown {
    font-size: 0.9rem;
  }
} */

/* Media Query for Mobile Devices (Up to 480px) */
/* @media (max-width: 480px) {
  .responsive-table thead {
    display: none; 
  }

  .responsive-table tr {
    display: block;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--navColor41);
  }

  .responsive-table td {
    display: block;
    text-align: right;
    padding-left: 70%;
    position: relative;
    border: none;
    border-bottom: 1px solid #eee;
  }

  .responsive-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .massager-dropdown {
    width: 100%;
    font-size: 0.85rem;
    padding: 8px;
  }

  .responsive-table .btn {
    padding: 6px 10px;
    font-size: 0.9rem;
  }
} */
