.gallery-container {
  display: flex;
  align-items: center; /* Center images and arrows vertically */
  justify-content: center; /* Center the container horizontally */
  overflow: hidden; /* Hide overflow to avoid horizontal scrolling */
  padding: 10px;
  gap: 30px;
  background-color: var(--navColor11);
  /* border-radius: 10px; */
  margin: 8px auto; /* Center the container */
}

.Garrow {
  background-color: transparent; /* Make button background transparent */
  border: none; /* Remove border */
  font-size: 24px; /* Size of the arrows */
  cursor: pointer; /* Change cursor to pointer */
  opacity: 0.7; /* Slightly transparent */
  transition: opacity 0.3s;
  min-width: 30px; /* Ensure buttons have a minimum width */
}

.Garrow:hover {
  opacity: 1; /* Fully opaque on hover */
}

.Garrow:disabled {
  opacity: 0.3; /* Disabled state */
  cursor: not-allowed; /* Change cursor for disabled */
}

.gallery-container::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.gallery-container::-webkit-scrollbar-thumb {
  background: var(--navShadowColor7); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the scrollbar thumb */
}

.gallery-container::-webkit-scrollbar-track {
  background: var(--navShadowColor); /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the track */
}

.gallery-image {
  width: 200px; /* Set the width for images */
  height: 150px; /* Fixed height for uniformity */
  object-fit: cover; /* Ensure the image covers the entire area */
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth effects */
  box-shadow: 0 4px 15px var(--navShadowColor5); /* Add shadow */
}

.gallery-image:hover {
  transform: scale(1.12); /* Scale up on hover */
  box-shadow: 0 6px 20px var(navShadowColor2); /* Deeper shadow on hover */
}

@media (max-width: 1200px) {
  .gallery-image {
    width: 180px;
    height: 135px;
  }
}

@media (max-width: 900px) {
  .gallery-image {
    width: 160px;
    height: 120px;
  }
}

@media (max-width: 600px) {
  .gallery-container {
    gap: 10px;
  }
  .Garrow {
    font-size: 18px; /* Adjust size for mobile */
    /* border: 2px solid red; */
  }
  .gallery-image {
    width: 105px;
    height: 120px;
  }
}

@media (max-width: 400px) {
  .Garrow {
    font-size: 10px; /* Smaller arrow size on very small screens */
  }

  .gallery-image {
    width: 100px;
    height: 120px;
  }
}
